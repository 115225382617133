<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000"
        style="width: 60%"
    >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          {{ $t('create_new_iec') }}
        </v-card-title>

        <v-card-text>
          <div class="ma-2">
            <h5>{{ $t('select_device') }}</h5>
          </div>
          <div class="ma-2">
            <v-autocomplete
                v-model="selected_device"
                :items="device_serial_numbers"
                outlined
            ></v-autocomplete>
          </div>

          <div v-for="item in request_elements" v-bind:key="`request_element_${item.index}`">
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="10">
                <IEC1107RequestElement @updated="calculateIfRequestsValid()"
                                       :ref="`request_element_${item.index}`"></IEC1107RequestElement>
              </v-col>
              <v-col cols="2" style="text-align: center">
                <v-btn
                    icon
                    @click="deleteRequestElement(item.index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div class="mt-3">
            <v-btn
                @click="request_elements.push({index: request_elements.length > 0 ? Math.max(...request_elements.map(o => o.index))+1 : 0})"
            >
              {{ $t('add_element') }}
            </v-btn>
          </div>
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions class="text-center">
          <v-btn
              color="secondary"
              :disabled="selected_device === '' || !request_valid"
              block
              @click="addNewRequest()"
          >
            {{ $t('add_request') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import IEC1107RequestElement from "@/components/iec1107/requests/IEC1107RequestElement";
import axios from "axios";
import common_translations from "@/_helpers/common_translations";

export default {
  name: "IEC1107RequestEditor",
  components: {IEC1107RequestElement},
  props: ['openRequestDialog'],
  data() {
    return {
      dialog: false,
      selected_device: "",
      request_elements: [],
      request_valid: false
    }
  },
  mounted() {
    this.num_elements = []
  },
  computed: {
    ...mapState('amr', ['devices']),
    device_serial_numbers() {
      return this.devices.map(device => device.uid)
    },
  },
  methods: {
    ...mapActions('amr', ['get_devices']),
    openModal() {
      this.dialog = true;
    },
    deleteRequestElement(index) {
      console.log(`Deleting ${index}`)
      let element_index = this.request_elements.findIndex(element => element.index === index)
      this.request_elements.splice(element_index, 1)
    },
    addNewRequest() {
      /*
        adding new request to database
       */

      let request = {
        action: 'create_request',
        uid: this.selected_device,
        requests: []
      }
      console.log(this.$refs)
      for (let i = 0; i < this.request_elements.length; i++) {
        request.requests.push(this.$refs[`request_element_${this.request_elements[i].index}`][0].getData())
      }

      return axios.post('/api/amr/iec1107',
          request
          ,
          {
            params: {},
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            console.log("Got iec1007 requests");
            console.log(response)
            this.get_devices()


            if (response.data.status === 0) {
              this.$notify({
                group: 'amr',
                title: 'IEC1107 request',
                text: 'Created sucesfully',
                type: 'success'
              });
              this.dialog = false;
              // refresh request list
              this.$root.$emit('refreshIEC1107Requests');
            } else {
              this.$notify({
                group: 'amr',
                title: 'IEC1107 request',
                text: response.data.message,
                type: 'error'
              });
            }
          }).catch(error => {
            console.log(`Iec request server error=${error}`)
            this.$notify({
              group: 'amr',
              title: 'IEC1107 request',
              text: 'Server error',
              type: 'success'
            });
          })
    },
    calculateIfRequestsValid() {
      console.log('calculating')
      if (this.request_elements.length === 0) {
        this.request_valid = false
        return
      }

      for (let i = 0; i < this.request_elements.length; i++) {
        if (this.$refs[`request_element_${this.request_elements[i].index}`] === undefined) {
          this.request_valid = false
          return
        }
        if (!this.$refs[`request_element_${this.request_elements[i].index}`][0].isValid()) {
          this.request_valid = false
          return
        }
      }
      this.request_valid = true
    }
  },
  i18n: {
    messages: {
      sl: {
        create_new_iec: "Ustvari novo IEC1107 zahtevo",
        select_device: "Izberi napravo",
        add_element: "Dodaj element",
        add_request: "Dodaj zahtevo"
      },
      en: {
        create_new_iec: "Create new IEC1107 request",
        select_device: "Select device",
        add_element: "Add element",
        add_request: "Add request"
      },
    },
    sharedMessages: common_translations.iec1107_translations
  }
}
</script>

<style scoped>

</style>