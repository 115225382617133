const months =
    {
        en: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "September",
            "October",
            "November",
            "December"
        ],
        sl: [
            "Januar",
            "Februar",
            "Marec",
            "April",
            "Maj",
            "Junij",
            "Julij",
            "September",
            "Oktober",
            "November",
            "December"
        ]
    }


const week_days = {
    en: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ],
    sl: [
        "Ponedeljek",
        "Torek",
        "Sreda",
        "Četrtek",
        "Petek",
        "Sobota",
        "Nedelja"
    ]
}

export {months, week_days};