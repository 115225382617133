import {months, week_days} from "@/_helpers/date_utilities";

const zeroPad = (num, places) => String(num).padStart(places, '0')

function hex_date_to_string(hex_date) {
    let year = zeroPad(Number("0x" + hex_date.substring(0, 4)), 4)
    let month = zeroPad(Number("0x" + hex_date.substring(2 * 2, 3 * 2)), 2)
    let day_of_month = zeroPad(Number("0x" + hex_date.substring(3 * 2, 4 * 2)), 2)

    let hour = zeroPad(Number("0x" + hex_date.substring(5 * 2, 6 * 2)), 2)
    let minute = zeroPad(Number("0x" + hex_date.substring(6 * 2, 7 * 2)), 2)
    let seconds = zeroPad(Number("0x" + hex_date.substring(7 * 2, 8 * 2)), 2)

    return `${year}-${month}-${day_of_month} ${hour}:${minute}:${seconds}`
}

function hex_time_to_string(hex_time) {
    let hour = zeroPad(Number("0x" + hex_time.substring(0, 2)), 2)
    let minute = zeroPad(Number("0x" + hex_time.substring(1 * 2, 2 * 2)), 2)
    let seconds = zeroPad(Number("0x" + hex_time.substring(2 * 2, 3 * 2)), 2)
    let hundreths = zeroPad(Number("0x" + hex_time.substring(3 * 2, 4 * 2)), 2)
    return `${hour}:${minute}:${seconds}.${hundreths}`
}

function hex_date_interval_to_string(hex_date) {
    let year = Number("0x" + hex_date.substring(0, 4))
    let month = Number("0x" + hex_date.substring(2 * 2, 3 * 2))
    let day_of_week = Number("0x" + hex_date.substring(3 * 2, 4 * 2))
    let day_of_month = Number("0x" + hex_date.substring(4 * 2, 5 * 2))
    return `${year}.${month}.${day_of_week}.(${day_of_month})`
}

function hex_date_time_to_description(hex_date, hex_time, language) {
    if (hex_date.length !== 10 || hex_time.length !== 8)
        return "/"
    let ret = {
        "en": "Repeats ",
        "sl": "Ponovi se "
    }[language]

    if (hex_date === 'FFFFFFFFFF')
        ret += {
            "en": "every day ",
            "sl": "vsak dan "
        }[language]
    else {
        let year = Number("0x" + hex_date.substring(0, 4))
        if (year !== 0xFFFF)
            ret += {
                "en": `at year ${year}`,
                "sl": `leta ${year} `
            }[language]

        let month = Number("0x" + hex_date.substring(2 * 2, 3 * 2))
        if (month === 0xFF)
            ret += {
                "en": `monthly `,
                "sl": `mesečno `
            }[language]
        else
            ret += {
                "en": `every ${months[language][month - 1]} `,
                "sl": `vsak ${months[language][month - 1]} `
            }[language]
        let day_of_month = Number("0x" + hex_date.substring(3 * 2, 4 * 2))
        if (day_of_month !== 0xFF)
            ret += {
                "en": `${day_of_month}. day of month `,
                "sl": `${day_of_month}. dan v mesecu `
            }[language]
        let day_of_week = Number("0x" + hex_date.substring(4 * 2, 5 * 2))
        if (day_of_week !== 0xFF)
            ret += {
                "en": `every ${week_days[language][day_of_week - 1]} `,
                "sl": `vsak ${week_days[language][day_of_week - 1]} `
            }[language]
    }

    ret = ret.trimRight()   // delete any whitespace trailing
    ret += ", "

    let hour = Number("0x" + hex_time.substring(0, 2))
    if (hour === 0xFF)
        ret += {
            "en": "hourly ",
            "sl": "vsako uro "
        }[language]
    else if (hour === 0xF0) {
        ret += {
            "en": `every midnight `,
            "sl": `ob polnoči `
        }[language]
    } else if (hour > 0xF0 && hour < 0xFF) {
        ret += {
            "en": `every ${hour & 0xF} hours `,
            "sl": `vsakih ${hour & 0xF} ur `
        }[language]
    } else
        ret += {
            "en": `at ${hour}h `,
            "sl": `ob ${hour}h `
        }[language]
    let minute = Number("0x" + hex_time.substring(2, 2 * 2))
    if (minute === 0xFF)
        ret += {
            "en": `every minute `,
            "sl": `vsako minuto `
        }[language]
    else if (minute > 0xF0 && minute < 0xFF) {
        ret += {
            "en": `every ${minute & 0xF} minutes `,
            "sl": `vsakih ${minute & 0xF} minut `
        }[language]
    } else if (minute === 0xF0) {
        ret += {
            "en": `every hour `,
            "sl": `vsako uro `
        }[language]
    } else if(minute !== 0)
        ret += {
            "en": `${minute}min `,
            "sl": `${minute}min `
        }[language]
    // let seconds = Number("0x"+hex_time.substring(2 * 2, 3 * 2))
    // console.log(hex_time)
    return ret
}

export {hex_date_to_string, hex_time_to_string, hex_date_interval_to_string, hex_date_time_to_description}