<template>
  <div>
    <v-row class="d-flex justify-space-between pa-2">

      <v-col md="4" sm="12">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <div class="overline mb-4">
                {{ $t("main.total_devices") }}
              </div>
              <v-list-item-title class="headline mb-1">
                {{ statistics.all }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
              <v-icon color="red darken-1" x-large>mdi-server</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <div class="overline mb-4">
                <div>
                  <div class="d-inline">
                    {{ $t("main.package_success_rate") }}
                  </div>
                  <div class="d-inline">
                    <PackageSucessTooltip></PackageSucessTooltip>
                  </div>
                </div>

              </div>
              <v-list-item-title class="headline mb-1">
                {{ statistics.package_sucess_rate }} %

              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
              <v-icon color="red darken-1" x-large>mdi-percent-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <div class="overline mb-4">
                {{ $t("main.alarms_in_last_day") }}
              </div>
              <v-list-item-title class="headline mb-1">
                {{ statistics.alarms_in_last_24_hours }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
              <v-icon color="red darken-1" x-large>mdi-alarm-light-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-card>
          <!--<Chart></Chart>-->
          <!--<TrendLineChart></TrendLineChart>-->
          <VolumeLineChart :for-device="''" :for-all-devices="true"></VolumeLineChart>

        </v-card>
      </v-col>
      <!--
      <v-col cols="12" sm="12" class="pa-2" md="4">
        <v-card>

          <TamperHistory24h></TamperHistory24h>

        </v-card>
      </v-col>-->
    </v-row>

    <!-- alarms and TDB -->
    <v-row>
      <v-col md="8" sm="12">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <div class="overline mb-4">
                <div>
                  <div class="d-inline">
                    {{ $t("main.last_tamper_events") }}
                  </div>
                </div>

              </div>
              <v-list-item-title class="headline mb-1">

              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
            >
              <v-icon color="red darken-1" x-large>mdi-list-status</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <AmrTable :for_dashboard="true" :selected_dates="[]" :selected_devices="[]"
                    :selected_tabletype="2" :selected_consumption_points="[]" :is_in_modal="false"></AmrTable>


        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <TamperHistoryList></TamperHistoryList>
      </v-col>
    </v-row>

  </div>
</template>

<script>
// import Chart from "@/components/Chart";
// import TrendLineChart from "@/components/charts/trend-chart/TrendLineChart";
import {mapState, mapActions} from "vuex";
//import TamperHistory24h from "@/components/charts/apex-charts/TamperHistory24h";
//import TamperCircle from "@/components/charts/apex-charts/TamperCircle";
import VolumeLineChart from "@/components/charts/apex-charts/VolumeBarChart";
import common_translations from "@/_helpers/common_translations";
import AmrTable from "@/components/tables/AmrTable";
import PackageSucessTooltip from "@/components/widgets/PackageSucessTooltip";
import TamperHistoryList from "@/components/widgets/TamperHistoryList";

export default {
  name: "Dashboard",
  components: {TamperHistoryList, PackageSucessTooltip, VolumeLineChart, AmrTable},
  created() {
    console.log("Dashboard created");
    this.get_devices();
    this.get_statistics();
    this.get_alarms();
    this.get_tamper_history();
  },
  computed: {
    ...mapState('amr', ['statistics', 'alarms'])
  },
  data() {
    return {
      total_devices: 0
    }
  },
  methods: {
    ...mapActions('amr', ['get_statistics', 'get_alarms', 'get_devices', 'get_tamper_history']),
  },
  i18n: {
    messages: {
      en: {
        main: {
          total_devices: 'TOTAL DEVICES',
          package_success_rate: "PACKAGE SUCCESS RATE",
          alarms_in_last_day: "ALARMS IN LAST DAY",
          alarm_history: "ALARM HISTORY",
          tamper_events_in_last_day: "TAMPER EVENTS IN LAST DAY",
          last_tamper_events: "LAST TAMPER EVENTS"
        }
      },
      sl: {
        main: {
          total_devices: 'SKUPNO ŠTEVILO NAPRAV',
          package_success_rate: "USPEŠNOST PREJEMA PODATKOV",
          alarms_in_last_day: "ALARMI V ZADNJEM DNEVU",
          alarm_history: "ZGODOVINA ALARMOV",
          tamper_events_in_last_day: "IZJEMNI DOGODKI V ZADNJEM DNEVU",
          last_tamper_events: "ZADNJI IZJEMNI DOGODKI"
        }
      },
    },
    sharedMessages: common_translations.tamper_descriptions
  }
}
</script>

<style scoped>

</style>
