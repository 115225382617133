<template>
  <v-card class="ma-2">
    <v-row class="ma-2 justify-center align-center" align="center">
      <v-col cols="8">
        <v-autocomplete outlined
                        hide-details
            v-model="selected_model"
            :items="cosem_models"
            :label="`${$t('select_model')}`"
            :item-text="(item) => { return `${$t(item.description)} - (${item.logical_name} : ${item.attribute})` }"
            return-object
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" v-if="selected_model !== undefined">
        <v-radio-group row v-model="selected_mode" class="mt-1">
          <v-radio
              v-for="mode in getModes(selected_model.mode)"
              :key="mode.value"
              :label="mode.text"
              :value="mode.value"
          ></v-radio>
        </v-radio-group>

      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-col cols="12" v-if="this.selected_mode === 'write'">
        <!-- Error register 1 mask -->
        <div v-if="selected_model.logical_name === '7-0:97.98.1*255' && selected_model.attribute === 'A2'">
          <v-text-field
              :label="$t('mask')"
              v-model="values[0]"
          >
          </v-text-field>
        </div>
        <!-- Tilt shake sensitivity -->
        <div v-else-if="selected_model.logical_name === '0-0:128.3.26*255' && selected_model.attribute === 'A2'">
          <v-text-field
              :label="`${$t('sensitivity')} [%]`"
              v-model="values[0]"
          >
          </v-text-field>
        </div>
        <!-- pulse duration -->
        <div v-else-if="selected_model.logical_name === '0-0:128.3.61*255' && selected_model.attribute === 'A2'">
          <v-text-field
              :label="`${$t('pulse_duration')} [ms]`"
              v-model="values[0]"
          >
          </v-text-field>
        </div>
        <div v-else-if="selected_model.logical_name === '0-0:128.3.36*255' && selected_model.attribute === 'A2'">
          <v-text-field
              :label="`${$t('high_temperature_treshold')} (0,255)`"
              v-model="values[0]"
          >
          </v-text-field>
          <v-text-field
              :label="`${$t('low_temperature_treshold')} (-127,128)`"
              v-model="values[1]"
          >
          </v-text-field>
        </div>
        <div v-else-if="selected_model.logical_name === '7-0:99.1.0*255' && selected_model.attribute === 'A4'">
          <v-text-field
              :label="`${$t('capture_period')} [s]`"
              v-model="values[0]"
          >
          </v-text-field>
        </div>
        <div v-else-if="(selected_model.logical_name === '0-1:25.9.0*255' ||
                         selected_model.logical_name === '0-4:25.9.0*255' ||
                         selected_model.logical_name === '0-7:25.9.0*255' ||
                         selected_model.logical_name === '0-8:25.9.0*255') &&
                         selected_model.attribute === 'A3'">
          <v-text-field
              :label="`${$t('transport_type')} (0-TCP, 1-UDP, 4-SMS)`"
              v-model="values[0]"
          >
          </v-text-field>
          <v-text-field
              :label="`${$t('destination')}`"
              v-model="values[1]"
          >
          </v-text-field>
          <v-text-field
              :label="`${$t('message_type')} (0-AXDR encoded, 1-XML encoded, 128-Inda encoded)`"
              v-model="values[2]"
          >
          </v-text-field>
        </div>
        <div v-else-if="(selected_model.logical_name === '0-1:25.9.0*255' ||
                         selected_model.logical_name === '0-4:25.9.0*255' ||
                         selected_model.logical_name === '0-7:25.9.0*255' ||
                         selected_model.logical_name === '0-8:25.9.0*255') &&
                         selected_model.attribute === 'A6'">
          <v-text-field
              :label="`${$t('num_retries')}`"
              v-model="values[0]"
          >
          </v-text-field>
        </div>
        <div v-else-if="selected_model.logical_name === '0-0:15.0.4*255' && selected_model.attribute === 'A4'">
          <TimeSendingIntervals
              :values="values" @request-element-valid="requestElementValid"
          ></TimeSendingIntervals>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import common_translations from "@/_helpers/common_translations";
import TimeSendingIntervals from "@/components/iec1107/requests/specials/TimeSendingIntervals";

export default {
  name: "IEC1107RequestElement",
  components: {TimeSendingIntervals},
  data() {
    return {
      selected_model: undefined,
      selected_mode: undefined,
      values: [],
      request_element_valid: true
    }
  },
  created() {
    this.$emit('updated')
  },
  destroyed() {
    this.$emit('updated')
  },
  watch: {
    selected_model: function () {
      this.$emit('updated')
    },
    selected_mode: function () {
      this.$emit('updated')
    },
    values: function () {
      this.$emit('updated')
    },
    request_element_valid: function() {
      this.$emit('updated')
    }
  },
  computed: {
    ...mapState('amr', ['cosem_models']),
    cosem_model_descriptions() {
      return this.cosem_models.map(model => `${this.$t(model.description)} (${model.logical_name})`)
    }
  },
  methods: {
    getModes(mode) {
      /*
        create an array of translatable v-select for modes
        text: translatable mode
        value: actual iec mode
       */
      if (mode === undefined)
        return []
      // splits read/write or read/write/execute and adds translation
      let ret = mode.split('/').map(mode => ({text: this.$t(mode), value: mode}))
      return ret
    },
    getData() {
      return {
        cosem_model: this.selected_model,
        mode: this.selected_mode,
        values: this.values
      }
    },
    isValid() {
      if (this.selected_model === undefined)
        return false
      if (this.selected_mode === undefined)
        return false
      if (this.selected_mode === 'write' && this.values.length === 0)
        return false
      if(!this.request_element_valid)
        return false
      return true
    },
    requestElementValid(valid) {
      console.log('valid ', valid)
      this.$emit('updated')
      this.request_element_valid = valid
    }
  },
  i18n: {
    messages: {
      sl: {
        read: 'Beri',
        write: 'Vpiši',
        execute: 'Izvedi',
        select_model: 'Izberi COSEM model'
      },
      en: {
        read: 'Read',
        write: 'Write',
        execute: 'Execute',
        select_model: 'Select COSEM model'
      }
    },
    sharedMessages: common_translations.joined_translations
  }
}
</script>

<style scoped>

</style>