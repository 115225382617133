<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('report_settings') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('set_display_options') }}
          </v-card-subtitle>
          <v-card-text>
            <v-container class="px-0"
                         fluid>
              <v-switch inset
                        v-model="table_settings.show_unique_id"
                        :label="$t('show_unique_id')"
                        @click="saveSetting('show_unique_id', table_settings.show_unique_id)"
              ></v-switch>
              <v-switch inset
                        v-model="table_settings.show_consumption_point"
                        :label="$t('show_consumption_point')"
                        @click="saveSetting('show_consumption_point', table_settings.show_consumption_point)"
              ></v-switch>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: "Settings.vue",
  data() {
    return {}
  },
  created() {
    console.log("Settings created");
  },
  computed: {
    ...mapState('amr', ['table_settings'])
  },
  methods: {
    ...mapMutations('amr', ['make_setting']),
    saveSetting(key, value) {
      this.make_setting({key: key, value: value})
    }
  },
  i18n: {
    messages: {
      en: {
        report_settings: "Report settings",
        set_display_options: "Set your display options",
        show_unique_id: "Show Unique ID in table",
        show_consumption_point: "Show Consumption point in table"
      },
      sl: {
        report_settings: "Nastavitve poročil",
        set_display_options: "Prikaz v tabelah",
        show_unique_id: "Prikaži unikatni ID naprave v tabeli",
        show_consumption_point: "Prikaži odjemno mesto naprave v tabeli"
      },
    }
  }
}
</script>

<style scoped>

</style>
