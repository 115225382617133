import userService from '../_services/user.service';
import router from '../router/router';


const cur_user = localStorage.getItem('token');
//const state = cur_user
//    ? { status: { loggedIn: true }, cur_user }
//    : { status: {}, user: null };
//
const state = {
    status: {
        loggedIn: !!cur_user,
    },
    user: cur_user ? cur_user : null,
    user_data: {
        company: {
            supplier_label: "UNKNOWN",
            setting_can_enter_gas_id: false
        }
    },
    language: localStorage.getItem("locale") === null ? "sl" : localStorage.getItem("locale"),
    i18n: null
}

const actions = {
    login({dispatch, commit}, {username, password}) {
        commit('loginRequest', {username});
        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    userService.getUserData().then(r => commit("setUserData", r));
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error.message, {root: true});
                }
            );
    },
    getUserData({commit}) {
       userService.getUserData().then(r => commit("setUserData", r));
    },
    logout({commit}) {
        userService.logout();
        commit('logout');
    },
    set_language({commit}, language)
    {
        commit('setLanguage', language);
    }
};

const mutations = {
    setUserData(state, user_data) {
        console.log(`Setting user data ${user_data}`)
      state.user_data = user_data
    },
    loginRequest(state, user) {
        state.status = {loggingIn: true};
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = {loggedIn: true};
        state.user = user;
    },
    loginFailure(state) {
        state.status = {loggedIn: false};
        state.user = null;
    },
    logout(state) {
        state.status = {loggedIn: false};
        state.user = null;
    },
    setLanguage(state, language) {
        state.language = language;
    },
    registerRequest(state) {
        state.status = {registering: true};
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

export const user = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default user;
