<template>
  <div v-if="for_dashboard">
    <!-- don't embedd it in row's -- might be duplicated with parent on dashboard -->
    <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :loading="loading"
        :loading-text="loading_text"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:item.measurement="{ item }">
        {{ item.v_battery }} V | {{ item.amb_temp }}
        &#8451;
        | {{ item.rssi }} dB

        <!-- add battery capacity -->
        <template v-if="item.battery_capacity">
          | {{ item.battery_capacity }}%
        </template>
      </template>

      <template v-slot:item.tamper_description="{ item }">
        <!--<div v-html="item.tamper_description" >{{ item.tamper_description }}</div>-->
        <div style="white-space: pre">{{ item.tamper_description.map(element => $t(element)).join("\r\n") }}</div>
      </template>
    </v-data-table>
  </div>
  <div v-else>
    <v-row dense v-if="selected_tabletype !== table_types.INSTALL_EVENT_TABLE && !is_in_modal && !for_dashboard">
      <v-col cols="12">

        <!--
        <v-btn class="m-2 float-right"
               v-if="selected_tabletype !== table_types.TAMPER_TABLE && selected_tabletype !== table_types.EVENT_LOG_TABLE">
          <v-icon>mdi-chart-bell-curve</v-icon>
          {{ $t("Show chart") }}
        </v-btn>-->


        <v-btn class="m-2 float-right" @click="getCsv(event_type)">
          <v-icon>mdi-file-delimited</v-icon>
          {{ $t("Export to .CSV") }}
        </v-btn>

        <v-btn class="m-2 float-right" @click="show_full = true; getData(true)">
          <v-icon>mdi-arrow-all</v-icon>
          {{ $t("Show all") }}
        </v-btn>

      </v-col>

      <hr/>
    </v-row>


    <v-row dense>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="data"
            item-key="name"
            class="elevation-1"
            :loading="loading"
            :loading-text="loading_text"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
        >
          <template v-slot:item.measurement="{ item }">
            {{ item.v_battery }} V | {{ item.amb_temp }}
            &#8451;
            | {{ item.rssi }} dB

            <!-- add battery capacity -->
            <template v-if="item.battery_capacity">
              | {{ item.battery_capacity }}%
            </template>

          </template>

          <template v-slot:item.tamper_description="{ item }">
            <!--<div v-html="item.tamper_description" >{{ item.tamper_description }}</div>-->
            <div style="white-space: pre">{{ item.tamper_description.map(element => $t(element)).join("\r\n") }}</div>
          </template>

          <template v-slot:item.log="{ item }">
            <!-- translating log messages-->
            {{ dynamic_translate(item.log) }}
          </template>

        </v-data-table>

        <!-- bottom pagination -->
        <div class="text-center pt-2" v-if="!for_dashboard">
          <v-pagination
              v-model="page"
              :length="events_length"
              :total-visible="10"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <!-- dialog for modal full events -->
    <v-dialog
        v-model="show_full"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t("Full events") }}
        </v-card-title>

        <v-fab-transition>
          <v-btn
              @click="show_full = false"
              v-show="true"
              color="pink"
              fab
              dark
              small
              fixed
              bottom
              style="left: 50%; margin-bottom: 3em;justify-content: center; margin-left:-20px"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="full_data"
              item-key="name"
              class="elevation-1"
              :loading="full_loading"
              :loading-text="loading_text"
              hide-default-footer
              :items-per-page="total_events"
          >
            <template v-slot:item.measurement="{ item }">
              {{ item.v_battery }} V | {{ item.amb_temp }}
              &#8451;
              | {{ item.rssi }} dB
            </template>

            <template v-slot:item.log="{ item }">
              <!-- translating log messages-->
              {{ dynamic_translate(item.log) }}
            </template>

            <template v-slot:item.tamper_description="{ item }">
              <!--<div v-html="item.tamper_description" >{{ item.tamper_description }}</div>-->
              <div style="white-space: pre">{{ item.tamper_description.map(element => $t(element)).join("\r\n") }}</div>
            </template>

          </v-data-table>
        </v-card-text>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import common_translations from "@/_helpers/common_translations";

export const TableType = {
  UNDEFINED_TABLE: 0,
  VOLUME_TABLE: 1,
  TAMPER_TABLE: 2,
  LOADPROFILE_TABLE: 3,
  INSTALL_EVENT_TABLE: 4,
  EVENT_LOG_TABLE: 5,
  DEVICE_LOG_TABLE: 6
}

export default {
  name: "AmrTable",
  data() {
    return {
      data: [],
      full_data: [],
      full_loading: false,
      loading: false,
      loading_text: 'Fetching data...',
      timer: null,
      page: 1,
      events_length: 0,
      total_events: 0,
      show_all: false,
      table_types: TableType,
      show_full: false,
    }
  },
  props: ['selected_dates', 'selected_devices', 'selected_consumption_points', 'selected_tabletype', 'is_in_modal', 'for_dashboard', 'start_time', 'end_time'],
  mounted() {
    this.timer = setInterval(() => {
      this.getData();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.getData();
  },
  watch: {
    start_time: function () {
      this.getData();
    },
    end_time: function () {
      this.getData();
    },
    selected_dates: function () {
      this.getData();
    },
    selected_devices: function () {
      this.getData();
      // when rendering tables always go back to page one so users don't get confused
      this.page = 1;
    },
    selected_consumption_points: function () {
      this.getData();
      // when rendering tables always go back to page one so users don't get confused
      this.page = 1;
    },
    page: function () {
      this.getData();
    }
  },
  computed: {
    VolumeHeaders() {
      return [
        {text: this.$t("Device ID"), value: "uid"},
        {text: this.$t("Consumption point"), value: "consumption_point"},
        {text: this.$t("Time"), value: "time"},
        {text: `${this.$t("Volume")} [m³]`, value: "volume"},
        //{text: "Tamper", value: "tamper"},
        {text: this.$t("Tamper"), value: "tamper_description"},
        //{text: "RSSI", value: "rssi"},
        {text: this.$t("Area"), value: "area_id"},
        //{text: "Batt [V]", value: "v_battery"},
        //{text: "Temp", value: "amb_temp"},
        {text: this.$t("Measurements"), value: "measurement", sortable: false}
      ]
    },
    TamperHeaders() {
      return [
        {text: this.$t("Device ID"), value: "uid"},
        {text: this.$t("Consumption point"), value: "consumption_point"},
        {text: this.$t("Time"), value: "time"},
        //{text: "Tamper", value: "tamper"},
        {text: this.$t("Tamper"), value: "tamper_description"},
        //{text: "RSSI", value: "rssi"},
        {text: this.$t("Area"), value: "area_id"},
        //{text: "Batt. [V]", value: "v_battery"},
        //{text: "Amb. Temp", value: "amb_temp"},
        {text: this.$t("Measurements"), value: "measurement", sortable: false}
      ]
    },
    EventLogHeaders() {
      return [
        {text: this.$t("Device ID"), value: "uid"},
        {text: this.$t("Consumption point"), value: "consumption_point"},
        {text: this.$t("Time"), value: "time"},
        {text: this.$t("Tamper"), value: "tamper_description"}
      ]
    },
    DeviceLogHeaders() {
      return [
        {text: this.$t("Device ID"), value: "uid"},
        //{text: this.$t("Consumption point"), value: "consumption_point"},
        {text: this.$t("Time"), value: "time"},
        {text: this.$t("Event"), value: "log"}
      ]
    },
    LoadProfileHeaders() {
      return [
        {text: this.$t("Device ID"), value: "uid"},
        {text: this.$t("Consumption point"), value: "consumption_point"},
        {text: this.$t("Time"), value: "time"},
        {text: `${this.$t("Volume")} [m³]`, value: "volume"}
      ]
    },
    InstallEventHeaders() {
      return [
        {text: this.$t("Device ID"), value: "uid"},
        {text: this.$t("Time"), value: "time"},
        {text: this.$t("Counts"), value: "volume"},
        //{text: "Tamper", value: "tamper"},
        {text: this.$t("Tamper"), value: "tamper_description"},
        //{text: "RSSI", value: "rssi"},
        {text: this.$t("Area"), value: "area_id"},
        //{text: "Batt [V]", value: "v_battery"},
        //{text: "Temp", value: "amb_temp"},
        {text: this.$t("Measurements"), value: "measurement"}
      ]
    },
    ...mapState('amr', ['table_settings']),
    ...mapState('user', ['language']),
    type: function () {
      return this.selected_tabletype;
    },
    headers() {
      let headers = [];
      if (this.$props.selected_tabletype === TableType.VOLUME_TABLE)
        headers = this.VolumeHeaders;
      else if (this.$props.selected_tabletype === TableType.TAMPER_TABLE)
        headers = this.TamperHeaders;
      else if (this.$props.selected_tabletype === TableType.LOADPROFILE_TABLE)
        headers = this.LoadProfileHeaders;
      else if (this.$props.selected_tabletype === TableType.INSTALL_EVENT_TABLE)
        headers = this.InstallEventHeaders
      else if (this.$props.selected_tabletype === TableType.EVENT_LOG_TABLE)
        headers = this.EventLogHeaders
      else if (this.$props.selected_tabletype === TableType.DEVICE_LOG_TABLE)
        headers = this.DeviceLogHeaders
      else
        headers = []

      // filter headers
      if (!this.table_settings.show_consumption_point)
        headers = headers.filter(header => header.value !== "consumption_point")

      if (!this.table_settings.show_unique_id && this.selected_tabletype !== TableType.INSTALL_EVENT_TABLE)
        headers = headers.filter(header => header.value !== "uid")

      return headers
    },
    event_type() {
      if (this.type === TableType.VOLUME_TABLE)
        return 'volume_event';
      else if (this.type === TableType.TAMPER_TABLE)
        return 'tamper_event';
      else if (this.type === TableType.LOADPROFILE_TABLE)
        return 'load_profile';
      else if (this.type === TableType.INSTALL_EVENT_TABLE)
        return 'install_event';
      else if (this.type === TableType.EVENT_LOG_TABLE)
        return 'event_log'
      else if (this.type === TableType.DEVICE_LOG_TABLE)
        return 'device_log'
      else
        return ''
    },
    itemsPerPage() {
      if (this.for_dashboard)
        return 10;
      else
        return 20
    }
  },
  methods: {
    getData(full = false) {
      if (full)
        this.full_loading = true;
      else
        this.loading = true
      return axios.get('/api/amr/events', {
        params: {
          full: full,
          selected_dates: this.selected_dates === null ? null : this.selected_dates.join(),
          selected_devices: this.selected_devices.join(),
          selected_consumption_points: this.selected_consumption_points.join(),
          event_type: this.event_type,
          items_start: full ? 0 : (this.page - 1) * this.itemsPerPage,
          items_end: full ? this.total_events : (this.page - 1) * this.itemsPerPage + this.itemsPerPage,
          start_time: this.start_time,
          end_time: this.end_time
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            // console.log("Got data");
            if (full)
              this.full_data = response.data.events;
            else
              this.data = response.data.events;
            this.events_length = Math.floor(response.data.count / this.itemsPerPage) + 1;
            this.total_events = response.data.count;
            if (full)
              this.full_loading = false;
            else
              this.loading = false
          })
    },
    getCsv(event_type) {
      return axios.get('/api/amr/events', {
        params: {
          selected_dates: this.selected_dates === null ? null : this.selected_dates.join(),
          selected_devices: this.$props.selected_devices.join(),
          selected_consumption_points: this.selected_consumption_points.join(),
          event_type: event_type,
          to_file: "csv",
          start_time: this.start_time,
          end_time: this.end_time
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            console.log("Got csv");
            const blob = new Blob([response.data], {type: "text/csv"})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            let date_str = new Date().toISOString().substring(0, 10);
            link.download = `export_${event_type}_${date_str}.csv`
            link.click()
            URL.revokeObjectURL(link.href);
          })
    },
    dynamic_translate(item) {
      // console.log(`translating to ${this.language}`, item)
      // console.log(common_translations.history_translations[this.language]);
      let translated = item
      for (let message in common_translations.history_translations[this.language]) {
        let translation = common_translations.history_translations[this.language][message]
        let position = item.search(message)
        if (position >= 0) {
          // console.log(`Translation found ${message} ${translation}`)
          translated = translated.replace(message, translation)
        }
      }
      // console.log("translated ", translated)
      return translated;
    }
  },
  i18n: {
    messages: {
      sl: {
        "Show all": "Pokaži vse",
        "Export to .CSV": "Izvozi v .CSV",
        "Show chart": "Prikaži graf",
        "Gas initial value changed from {one} to {two}": "Začetna vrednost spremenjena iz {one} na {two}",
        "Full events": "Vsi dogodki",
        "Event": "Dogodek"
      }
    },
    sharedMessages: common_translations.joined_translations
  }
}

</script>

<style scoped>

</style>
