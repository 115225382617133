<template>
  <div>
    <v-app-bar clipped-left app height="75"
               style="margin-bottom: 10px; border-bottom: 0px solid #ed0909;
               background-image: linear-gradient(#757575, white);
               background-color: #F5F5F5">



      <div class="d-sm-flex d-md-none">
        <v-btn
            dark
            icon
            @click="OpenDrawer()"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <!-- inda logo in the center of navbar -->
      <div v-if="!$vuetify.breakpoint.mobile">
        <v-img :src="require('@/assets/inda_logo.png')"
               style="width: 160px; display: block; margin-top: 0.5em; margin-bottom:0.5em"></v-img>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-show="status.loggedIn" text @click="handleLogout()">
        <v-icon>mdi-logout</v-icon>
        <div>{{ $t("logout") }}</div>
      </v-btn>


      <!-- language selector -->
      <v-menu
          left
          bottom
          v-if="status.loggedIn"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <div v-if="language === 'sl'">
              <country-flag country='si' size='normal'/>
            </div>
            <div v-else-if="language === 'en'">
              <country-flag country='gb' size='normal'/>
            </div>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="set_language('sl'); setLanguage('sl')">
            <country-flag country='si' size='normal'/>
          </v-list-item>

          <v-list-item @click="set_language('en'); setLanguage('en')">
            <country-flag country='gb' size='normal'/>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>


  </div>


</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: "NavBar",
  data() {
    return {
      langs: ['sl', 'en'],
      selected_lang: 'sl',
      width: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.width = window.innerWidth;
    });
  },
  computed: {
    ...mapState('user', ['status', 'language', 'user_data'])
  },
  methods: {
    ...mapActions('user', ['logout', 'set_language']),
    handleLogout() {
      this.logout();
      location.reload(true);
    },
    OpenDrawer() {
      console.log("Opening navigation");
      this.$root.$emit('open_mobile_menu');
    },
    setLanguage(language) {
      this.$root.$i18n.locale = language;
      localStorage.locale = language;
      document.location.reload();
    }
  },
  i18n: {
    messages: {
      en: {
        logout: "Logout"
      },
      sl: {
        logout: "Odjava"
      }
    }
  }
}
</script>

<style>

</style>


