import amrService from '@/_services/amr.service'

const state = {
    statistics: {
        all: 1,
        package_sucess_rate: 0,
        alarms_in_last_24_hours: 0,
        missing_devices: [],
    },
    settings: {},
    alarms: [],
    devices: [],
    device_ids: [], // array of device ids only - used for autocomplete
    consumption_points: [], // array of consumption points
    devices_to_watch: localStorage.getItem('devices_to_watch') !== null ? JSON.parse(localStorage.getItem('devices_to_watch')) : [],
    table_settings: {
        show_unique_id: localStorage.show_unique_id ? Boolean(localStorage.show_unique_id) : false,
        show_consumption_point: localStorage.show_consumption_point ?
            Boolean(localStorage.show_consumption_point) : true,
    },
    tamper_history: [],
    cosem_models: [],
    refresh_cnt: 0
}

const actions = {
    get_statistics({commit}) {
        amrService.get_device_stats().then(
            stats => {
                commit('statistics', stats);
                console.log(stats);
            })
    },
    get_settings({commit}) {
        amrService.get_settings().then(
            settings => {
                commit('settings', settings);
                console.log(settings);
            })
    },
    get_alarms({commit}) {
        amrService.get_alarms().then(
            alarms => {
                commit('alarms', alarms);
                console.log(alarms);
            })
    },
    get_devices({commit}) {
        amrService.get_devices().then(
            devices => {
                commit('devices', devices);
                console.log('loaded devices', devices);
            })
    },
    get_device({commit}, device_pk) {
        amrService.get_device(device_pk).then(
            device => {
                commit('device', device);
                console.log('loaded device', device);
            })
    },
    get_tamper_history({commit}) {
        amrService.get_tamper_history().then(
            history => {
                commit('tamper_history', history);
                console.log(history);
            })
    },
    add_device_to_watch({commit}, device) {
        commit('device_to_watch', device);
    },
    remove_device_to_watch({commit}, device) {
        commit('remove_device_to_watch', device);
    },
};

const mutations = {
    statistics(state, stats) {
        state.statistics = stats
    },
    cosem_models(state, cosem_models) {
        state.cosem_models = cosem_models
    },
    settings(state, settings) {
        state.settings = settings
    },
    alarms(state, alarms) {
        state.alarms = alarms
    },
    tamper_history(state, history) {
        state.tamper_history = history;
    },
    devices(state, devices) {
        state.devices = devices
        state.device_ids = Object.keys(devices).map(key => devices[key].uid)
        state.consumption_points = Object.keys(devices).map(key => String(devices[key].consumption_point))
        state.refresh_cnt++;
    },
    device(state, device) {
        const index = state.devices.findIndex(obj => obj.uid === device.uid)
        if (index >= 0) {
            console.log(`updating device ${index}`)
            state.devices[index] = device
            state.refresh_cnt++;
        }
    },
    device_to_watch(state, new_device) {
        if (!(state.devices_to_watch.some(device => device.uid === new_device.uid)))
            state.devices_to_watch.push(new_device);
        localStorage.setItem('devices_to_watch', JSON.stringify(state.devices_to_watch));
    },
    remove_device_to_watch(state, exsisting_device) {
        const index = state.devices_to_watch.findIndex(device => device.uid === exsisting_device.uid)
        if(index >= 0) {
            state.devices_to_watch.splice(index, 1)
            localStorage.setItem('devices_to_watch', JSON.stringify(state.devices_to_watch));
        }

    },
    make_setting(state, setting) {
        console.log(`making new setting ${setting}`);
        if (setting.key === 'show_consumption_point') {
            state.table_settings.show_consumption_point = setting.value;
            localStorage.show_consumption_point = state.table_settings.show_consumption_point;
        } else if (setting.key === 'show_unique_id') {
            state.table_settings.show_unique_id = setting.value;
            localStorage.show_unique_id = state.table_settings.show_unique_id;
        } else
            console.log('Unknown setting')
    }
};

export const amr = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default amr;
