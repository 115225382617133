<template>
  <v-navigation-drawer
      height="100vh"
      app
      v-model="drawer"
  >
    <v-list
        dense
        nav
        active-class="highlighted"
        permanent
    >
      <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="$router.push({path: item.path}).catch(()=>{}); currentSelection = item.title;"
          :class="currentSelection == item.title ? 'grey lighten-3': ''"
          link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- selected devices -->
    <template v-if="devices_to_watch.length > 0">
      <v-list
          dense
          nav
          active-class="highlighted"
      >
        <hr/>
        <div class="overline">
          Selected devices
        </div>
        <v-list-item
            v-for="item in devices_to_watch"
            :key="item.uid"
            @click="$router.push({ path: `/amr/device/${item.uid}`});
                          currentSelection = item.uid;"
            :class="currentSelection == item.uid ? 'grey lighten-3': ''"
            link
            dense
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-gauge</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.uid }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NavigationDrawerMobile",
  data() {
    return {
      currentSelection: '',
      items: [
        {title: this.$t("message.dashboard"), icon: 'mdi-view-dashboard', path: '/amr/dashboard'},
        {title: this.$t("message.devices"), icon: 'mdi-devices', path: '/amr/devices'},
        {title: this.$t("message.reports"), icon: 'mdi-file-chart', path: '/amr/reports'},
        // {title: 'Alarms', icon: 'mdi-alarm-light', path: '/'},
        {title: this.$t("message.settings"), icon: 'mdi-cog', path: '/amr/settings'},
        {title: this.$t("message.iec1107"), icon: 'mdi-nfc-tap', path: '/amr/iec1107'}
      ],
      mini: true,
      drawer: false,
    }
  },
  mounted() {
    this.$root.$on('open_mobile_menu', this.OpenNavigation);
    this.$root.$on('open_device', this.openDevice);
  },
  computed: {
    ...mapState('amr', ['statistics', 'devices_to_watch'])
  },
  methods: {
    OpenNavigation() {
      console.log("GOT");
      this.drawer = true;
    },
    openDevice(device_uid) {
      this.currentSelection = device_uid;
    }
  },
  i18n: {
    messages: {
      en: {
        message: {
          dashboard: 'Dashboard',
          devices: "Devices",
          reports: "Reports",
          settings: "Settings",
          iec1107: "IEC1107 requests"
        }
      },
      sl: {
        message: {
          dashboard: 'Pregled',
          devices: "Naprave",
          reports: "Poročila",
          settings: "Nastavitve",
          iec1107: "IEC1107 zahteve"
        }
      },
    }
  }
}
</script>

<style scoped>

</style>