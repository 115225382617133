<template>
  <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false; text=''"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "Snackbar",
  data: () => ({
    snackbar: false,
    text: 'My timeout is set to 2000.',
    timeout: 2000,
  }),
  computed: {
    ...mapGetters({alert: "alert/getAlertMessage", alertCnt: "alert/getAlertCnt"})
  },
  watch: {
    alert(new_value) {
      this.text = new_value;
    },
    alertCnt() {
      this.snackbar = true;
    }
  }
}
</script>

<style scoped>

</style>
