// import config from 'config';
// import authHeader from '../_helpers/auth_header';
import axios from 'axios';

export const userService = {
    login,
    logout,
    getUserData
};

function login(username, password) {
    console.log('Logging in');

    return axios.post(`/api/login`, {
        username: username,
        password: password
    })
        .then(function (response) {
            console.log(response);
            const data = response.data;
            console.log(data);
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }


            if (data.token) {
                console.log("Storing token")
                //store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', data.token);
                localStorage.setItem('token_expires_in', data.expires_in);
            }

            return Promise.resolve(data);
        })
        .catch(function (error) {
            console.log(`Login error ${error}`)
            if (error.response != undefined) {
                // const error_resp = { message: error.response.data.non_field_errors[0] };
                const error_resp = {message: "Failed to log in, check your username and password"};
                return Promise.reject(error_resp);
            }
            const error_resp = {message: "Server error"};
            return Promise.reject(error_resp)
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
}

function getUserData() {
    console.log('Getting user data');

    return axios.get(`/api/user`,{headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }})
        .then(function (response) {
            console.log(response);
            const data = response.data;
            console.log(data);
            if (response.status === 401) {

                return Promise.reject();
            }

            return Promise.resolve(data);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            const error_resp = {message: "Server error"};
            return Promise.reject(error_resp)
        })
}

export default userService;
