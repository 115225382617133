<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-row>
          <v-col>
            <v-autocomplete
                v-model="selected_devices"
                :items="device_ids"
                outlined
                dense
                chips
                small-chips
                :label="$t('search_device_ids')"
                multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <v-row>
          <v-col>
            <v-autocomplete
                v-model="selected_consumption_points"
                :items="consumption_points"
                outlined
                dense
                chips
                small-chips
                :label="$t('search_consumption_point')"
                multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="3" cols="12">
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between align-center">
              <v-btn @click="openStartTimeDialog($event)">{{ $t("add_start_time") }}</v-btn>
              <datetime class="pl-3" format="yyyy-MM-dd HH:mm:ss  " ref="start_time_dialog" v-model="start_time" type="datetime">
                <div v-show="start_time === ''">
                    <span class="description" slot="after">
                      {{ $t("start_time_not_set") }}
                    </span>
                </div>
              </datetime>
              <v-btn
                  class="pa-1"
                  small
                  v-if="start_time !== ''"
                  @click="start_time = ''"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="3" cols="12">
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between align-center">
              <v-btn @click="openEndTimeDialog($event)">{{ $t("add_end_time") }}</v-btn>
              <datetime format="yyyy-MM-dd HH:mm:ss" class="pl-3" ref="end_time_dialog" v-model="end_time" type="datetime">
              </datetime>
              <v-btn
                  class="pa-1"
                  small
                  v-if="end_time !== ''"
                  @click="end_time = ''"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>


    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs>
          <v-tab>
            <v-icon left>
              mdi-cup-water
            </v-icon>
            {{ $t("volume_events") }}
          </v-tab>
          <v-tab>
            <v-icon left>
              mdi-calendar-alert
            </v-icon>
            {{ $t("tamper_events") }}
          </v-tab>
          <v-tab>
            <v-icon left>
              mdi-progress-upload
            </v-icon>
            {{ $t("load_profile") }}
          </v-tab>

          <v-tab>
            <v-icon left>
              mdi-clipboard-list-outline
            </v-icon>
            {{ $t("event_log") }}
          </v-tab>

          <v-tab>
            <v-icon left>
              mdi-clipboard-list-outline
            </v-icon>
            {{ $t("install_events") }}
          </v-tab>


          <v-tab-item>
            <AmrTable :selected_dates="null" :selected_devices="selected_devices"
                      :selected_tabletype="1" :selected_consumption_points="selected_consumption_points"
                      :is_in_modal="false" :start_time="start_time" :end_time="end_time">
            </AmrTable>
          </v-tab-item>
          <v-tab-item>
            <AmrTable :selected_dates="null" :selected_devices="selected_devices"
                      :selected_tabletype="2" :selected_consumption_points="selected_consumption_points"
                      :is_in_modal="false" :start_time="start_time" :end_time="end_time"></AmrTable>
          </v-tab-item>
          <v-tab-item>
            <AmrTable :selected_dates="null" :selected_devices="selected_devices"
                      :selected_tabletype="3" :selected_consumption_points="selected_consumption_points"
                      :is_in_modal="false" :start_time="start_time" :end_time="end_time"></AmrTable>
          </v-tab-item>

          <v-tab-item>
            <AmrTable :selected_dates="null" :selected_devices="selected_devices"
                      :selected_tabletype="5" :selected_consumption_points="selected_consumption_points"
                      :is_in_modal="false" :start_time="start_time" :end_time="end_time"></AmrTable>
          </v-tab-item>

          <v-tab-item>
            <AmrTable :selected_tabletype="4" :selected_consumption_points="selected_consumption_points"
                      :selected_dates="null" :selected_devices="selected_devices"
                      :is_in_modal="false" :start_time="start_time" :end_time="end_time"></AmrTable>
          </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AmrTable from "@/components/tables/AmrTable";
import {Settings} from 'luxon'

export default {
  name: "Reports",
  components: {AmrTable},
  data() {
    return {
      dates: [],
      start_time: '',
      end_time: '',
      selected_dates_display: '',
      show_calendar: false,
      items: [],
      selected_devices: [],
      selected_consumption_points: []
    }
  },
  created() {
    this.getDevices();
  },
  mounted() {
    this.$nextTick(() => {
      Settings.defaultLocale = this.language
    })
  },
  computed: {
    ...mapState('amr', ['device_ids', 'consumption_points']),
    ...mapState('user', ['language']),
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
  methods: {
    ...mapActions('amr', ['get_devices']),
    getDevices() {
      this.get_devices();
    },
    openStartTimeDialog(event) {
      console.log('opening start time dialog' +
          '')
      this.$refs.start_time_dialog.open(event)
    },
    openEndTimeDialog(event) {
      this.$refs.end_time_dialog.open(event)
    }
  },
  i18n: {
    messages: {
      sl: {
        tamper_events: "Izjemni dogodki",
        volume_events: "Skupni odčitki",
        load_profile: "Profil porabe",
        event_log: "Zgodovina dogodkov",
        install_events: "Inštalacijska sporočila",
        search_device_ids: "Išči po serijski št.",
        search_consumption_point: "Išči po odjemnem mestu",
        select_dates: "Izberi datume",
        add_start_time: "Izberi začetni čas",
        start_time_not_set: "Začetni čas ni izbran",
        add_end_time: "Izberi končni čas",
        end_time_not_set: "Končni čas ni izbran"
      },
      en: {
        tamper_events: "Tamper events",
        volume_events: "Volume events",
        load_profile: "Load profile",
        event_log: "Event log",
        install_events: "Install events",
        search_device_ids: "Search by device ID",
        search_consumption_point: "Search by consumption point",
        select_dates: "Select dates",
        add_start_time: "Add start time",
        start_time_not_set: "Start time not set",
        add_end_time: "Add end time",
        end_time_not_set: "End time not set"
      },
    }
  }
}
</script>

<style scoped>

</style>
