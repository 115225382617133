<template>
  <div v-if="devices != undefined">
    <v-card-title>
      <!--
      <v-btn @click="$refs.add_device.dialog=true">
        <v-icon>mdi-plus</v-icon>
        Add device
      </v-btn>-->
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>

    <div>
      <v-data-table
          :headers="headers"
          :items="devices"
          item-key="name"
          class="elevation-1"
          :search="search"
          hide-default-footer
          disable-pagination
      >
        <template v-slot:item.uid="{ item }">
          <tr style="cursor: pointer" @click="handleClickRow(item)">{{ item.uid }}</tr>
        </template>

        <template v-slot:item.configured="{ item }">
          <template v-if="item.configured">
            <v-icon color="green">mdi-check-bold</v-icon>
          </template>
          <template v-else>
            <v-icon color="red">mdi-autorenew</v-icon>
          </template>
        </template>

        <template v-slot:item.installed="{ item }">
          <template v-if="item.installed">
            <div class="d-flex justify-center">
              <v-icon color="green">mdi-check-bold</v-icon>
            </div>
          </template>
          <template v-else>
            <v-icon color="red">mdi-do-not-disturb</v-icon>
          </template>
        </template>

        <!--<template v-slot:item.actions="{ item }">
          <v-icon
              small
              @click="deleteDevice(item)"
          >
            mdi-delete
          </v-icon>
        </template>-->


      </v-data-table>
    </div>

    <!-- moved to reports -->
    <!--
    <h5 class="m-5">Install events</h5>
    <div>
      <AmrTable :selected_tabletype="4" :selected_dates="[]" :selected_devices="[]"></AmrTable>
    </div>
    -->

    <div v-if="editedDevice">
      <v-dialog v-model="dialogDelete" max-width="80%">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete device {{ editedDevice.uid }}?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogDelete=false">Cancel</v-btn>
            <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <AddDevice ref="add_device"></AddDevice>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AddDevice from "@/components/AddDevice";
//import AmrTable from "@/components/tables/AmrTable";
import axios from "axios";
import common_translations from "@/_helpers/common_translations";

export default {
  name: "Devices",
  components: {AddDevice},
  data() {
    return {
      search: '',
      editedIndex: -1,
      editedDevice: null,
      dialogDelete: false
    }
  },
  computed: {
    ...mapState('amr', ['devices']),
    ...mapState('user', ['user_data']),
    headers() {
      let headers = [
        {
          text: this.$t('Unique ID'),
          value: 'uid',
        },
        {text: this.$t('Consumption point'), value: 'consumption_point'},
        {text: this.$t('Gas ID'), value: 'gas_uid'},
        {text: this.$t('Gas initial val'), value: 'gas_initval'},
        {text: this.$t('Supplier'), value: 'supplier'},
        {text: this.$t('Pulse count'), value: 'gas_constant'},
        {text: this.$t('Installed'), value: 'installed', align: 'center'},
        // configured is not needed anymore
        //{text: this.$t('Configured'), value: 'configured', align: 'center'},
        {text: this.$t('Installed on'), value: 'installed_on', align: 'center'},
        // {text: 'Actions', value: 'actions', sortable: false}
      ]

      if (!this.user_data.company.setting_can_enter_gas_id) {
        headers.splice(2,1)
      }
      return headers;
    }
  },
  mounted() {
    this.$root.$on('deviceChangeEvent', this.getDevices);
  },
  created() {
    console.log("Devices created");
    this.getDevices();
  },
  methods: {
    ...mapActions('amr', ['get_devices', 'add_device_to_watch']),
    ...mapActions('alert', ['success']),
    getDevices() {
      this.get_devices();
    },
    deleteDevice(device) {
      this.editedIndex = this.devices.indexOf(device)
      this.editedDevice = Object.assign({}, device)
      this.dialogDelete = true
    },
    editDevice(device) {
      this.editedIndex = this.devices.indexOf(device)
      this.editedDevice = Object.assign({}, device)
      this.$refs.add_device.uid = this.editedDevice.uid
      this.$refs.add_device.dialog = true
    },
    deleteItemConfirm() {
      axios.delete(`/api/amr/device/${this.editedDevice.id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
          .then(() => {
            this.dialogDelete = false,
                this.get_devices();
            this.success("Device deleted");
          })
    },
    handleClickRow(device) {
      this.add_device_to_watch(device);
      // go to device view
      this.$router.push({path: `/amr/device/${device.uid}`});
      // emit to navigation drawer there was a change
      this.$root.$emit('open_device', device.uid);

    }
  },
  i18n: {
    sharedMessages: common_translations.table_headers
  }
}
</script>

<style scoped>

</style>
