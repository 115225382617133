<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >

      <v-card>
        <v-card-title class="headline lighten-2">
          Add new Device
        </v-card-title>

        <v-card-text>

          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-text-field
                v-model="uid"
                label="Unique ID"
                required
                outlined
                :rules="textRules"
            ></v-text-field>

            <v-text-field
                v-model="gas_uid"
                label="Gas ID"
                required
                outlined
                :rules="textRules"
            ></v-text-field>

            <v-text-field
                v-model="supplier"
                label="Supplier"
                required
                outlined
                :rules="textRules"
            ></v-text-field>

            <v-text-field
                v-model="gas_constant"
                label="Gas Constant"
                type="number"
                required
                outlined
            ></v-text-field>

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="Submit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions} from "vuex";

export default {
  name: "AddDevice",
  data() {
    return {
      dialog: false,
      uid: '',
      gas_uid: '',
      supplier: '',
      gas_constant: 0,
      valid: false,
      textRules: [
        v => !!v || 'This is required',
      ],
      alert: null,
    }
  },
  methods: {
    ...mapActions('alert', ['success', 'error']),
    Submit() {
      if (this.valid) {
        this.alert = null,
            axios.post(`/api/amr/devices`, {
              uid: this.uid,
              gas_uid: this.gas_uid,
              supplier: this.supplier,
              gas_constant: this.gas_constant,
            }, {
              headers: {
                Authorization: `Token ${localStorage.getItem('token')}`
              }
            })
                .then((response) => {
                  if (response.status == 201) {
                    console.log("Device created sucessfully");
                    this.success("Device created sucessfully");
                    this.$root.$emit("deviceChangeEvent");
                    this.dialog = false;
                  }
                })
                .catch(() => {
                  console.log("Failed to save device");
                  this.error("Failed to create device");
                })
      }
    },
  }
}
</script>

<style scoped>

</style>
