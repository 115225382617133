<template>
  <v-dialog
      v-model="show"
      width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          dark
          v-bind="attrs"
          v-on="on"
          color="grey darken-1"
      >
        mdi-information-outline
      </v-icon>
    </template>

    <v-card>
      <v-card-text>

        <v-data-table
            :headers="headers"
            :items="statistics.missing_devices"
            item-key="name"
            class="elevation-1"
            hide-default-footer
            :items-per-page="statistics.missing_devices.length"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"

        >
          <template v-slot:item.elapsed_time="{ item }">
            <span>{{ item.elapsed_time.display }}</span>
          </template>
          <template v-slot:item.last_message="{ item }">
            <span>{{ item.last_message.display }}</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import common_translations from "@/_helpers/common_translations";

export default {
  name: "PackageSucessTooltip",
  data() {
    return {
      show: false,
      sortBy: 'elapsed_time',
      sortDesc: true,
      headers:
          [
            {text: this.$t("Device ID"), value: "uid"},
            {text: this.$t("Consumption point"), value: "consumption_point"},
            {
              text: this.$t("Last message time"),
              value: "last_message",
              sort: (a, b) => a.seconds - b.seconds
            },
            {
              text: this.$t("Elapsed time"),
              value: "elapsed_time",
              sort: (a, b) => a.days*86400+a.seconds - b.days*86400-b.seconds
            }
          ]

    }
  },
  computed: {
    ...mapState('amr', ['statistics', 'alarms', 'devices']),
  },
  methods: {
    ...mapActions('amr', ['add_device_to_watch']),
    handleClickRow(device) {
      console.log(`Adding device ${device} to watch`);
      let device_db = this.devices.find(dev => dev.uid === device);
      this.add_device_to_watch(device_db);
      // go to device view
      this.$router.push({path: `/amr/device/${device_db.uid}`});
      // emit to navigation drawer there was a change
      this.$root.$emit('open_device', device_db.uid);
    },
    getConsumptionPoint(uid) {
      let device_db = this.devices.find(dev => dev.uid === uid);
      return device_db.consumption_point;
    }
  },
  i18n: {
    messages: {
      sl: {
        "Last message time": "Zadnje sporočilo",
        "Elapsed time": "Pretečen čas",
        "Show chart": "Prikaži graf",
        "Gas initial value changed from {one} to {two}": "Začetna vrednost spremenjena iz {one} na {two}"
      }
    },
    sharedMessages: common_translations.joined_translations
  }
}
</script>

<style scoped>

</style>