<template>
  <v-navigation-drawer
      clipped
      app

  >
    <div v-if="user_data.company.supplier_label === 'DOMPLAN'">
      <v-img :src="require('@/assets/domplan_logo.jpg')"
             style="width: 240px; display: block; margin-right: auto; margin-left: auto; margin-top: 15px;"></v-img>
    </div>
    <div v-else-if="user_data.company.supplier_label === 'ENERGETIKA_LJUBLJANA'">
      <v-img :src="require('@/assets/energetika_logo.png')"
             style="width: 240px; display: block; margin-right: auto; margin-left: auto; margin-top: 15px;"></v-img>
    </div>
    <div v-else-if="user_data.company.supplier_label === 'GEORGE_WILSON_INDUSTRIES'">
      <v-img :src="require('@/assets/gwi_logo.jpg')"
             style="width: 120px; display: block; margin-right: auto; margin-left: auto; margin-top: 15px;"></v-img>
    </div>

    <div>
      <hr style="margin-top: 15px; margin-bottom: 8px"/>
    </div>
    <v-list
        style="margin-top:0"
        nav
        active-class="highlighted"
        permanent
    >
      <v-list-item
          v-for="item in allowedMenuItems"
          :key="item.id"
          @click="$router.push({path: item.path}).catch(()=>{}); currentSelection = item.title;"
          :class="currentSelection == item.title ? 'grey lighten-3': ''"
          link
      >
        <template >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>

    <!-- selected devices -->
    <template v-if="devices_to_watch.length > 0">
      <v-list
          nav
          active-class="highlighted"
      >
        <hr/>
        <div class="overline">
          {{ $t("message.selected_devices") }}
        </div>
        <v-list-item
            v-for="item in devices_to_watch"
            :key="item.uid"
            @click="$router.push({ path: `/amr/device/${item.uid}`});
                          currentSelection = item.uid;"
            :class="currentSelection == item.uid ? 'grey lighten-3': ''"
            link
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-gauge</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-list-item-title>{{ item.uid }}</v-list-item-title>
              </div>
              <div>
                <v-btn @click="removeDeviceToWatch(item)" icon  x-small><v-icon>mdi-close</v-icon></v-btn>
              </div>
            </div>

          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "NavigationDrawer",
  data() {
    return {
      currentSelection: '',
      items: [
        {id: 0, title: "message.dashboard", icon: 'mdi-view-dashboard', path: '/amr/dashboard'},
        {id: 1, title: "message.devices", icon: 'mdi-devices', path: '/amr/devices'},
        {id: 2, title: "message.reports", icon: 'mdi-file-chart', path: '/amr/reports'},
        // {title: 'Alarms', icon: 'mdi-alarm-light', path: '/'},
        {id: 3, title: "message.settings", icon: 'mdi-cog', path: '/amr/settings'},
        {id: 4, title: "message.iec1107", icon: "mdi-nfc-tap", path: '/amr/iec1107'}
      ],
      mini: true,
      drawer: false,
    }
  },
  mounted() {
    this.$root.$on('open_device', this.openDevice);
  },
  computed: {
    ...mapState('amr', ['statistics', 'devices_to_watch', 'cosem_models']),
    ...mapState('user', ['user_data']),
    allowedMenuItems: function() {
      return this.items
    }
  },
  watch: {},
  methods: {
    ...mapActions('amr', ['remove_device_to_watch']),
    openDevice(device_uid) {
      this.currentSelection = device_uid;
    },
    removeDeviceToWatch(item) {
      this.remove_device_to_watch(item)
    }
  },
  i18n: {
    messages: {
      en: {
        message: {
          dashboard: 'Dashboard',
          devices: "Devices",
          reports: "Reports",
          settings: "Settings",
          selected_devices: "Selected devices",
          iec1107: "IEC1107 requests"
        }
      },
      sl: {
        message: {
          dashboard: 'Pregled',
          devices: "Naprave",
          reports: "Poročila",
          settings: "Nastavitve",
          selected_devices: "Naprave",
          iec1107: "IEC1107 zahteve"
        }
      },
    }
  }
}
</script>

<style>

</style>