import Vue from 'vue';
import Vuex from 'vuex';

import alert from './alert.store';
import user from './user.store.js';
import amr from './amr.store.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        user,
        amr
    }
});
