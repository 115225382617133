import { render, staticRenderFns } from "./PackageSucessTooltip.vue?vue&type=template&id=bb8f325c&scoped=true"
import script from "./PackageSucessTooltip.vue?vue&type=script&lang=js"
export * from "./PackageSucessTooltip.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb8f325c",
  null
  
)

export default component.exports