import Vue from 'vue'
import App from './App.vue'

import Vuetify from 'vuetify';
import Notifications from 'vue-notification'
import { Datetime } from 'vue-datetime';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vue-datetime/dist/vue-datetime.css'

import router from './router/router.js';
import {store} from './_store';
import axios from "axios";
import VueI18n from "vue-i18n";
import CountryFlag from 'vue-country-flag';

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
      router.push("/");
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
 }
);

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(Vuetify);
Vue.use(Notifications)
Vue.component('datetime', Datetime);

Vue.component('country-flag', CountryFlag)

// Vue.use(VueAxios, axios)
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '//localhost:7000/';
}

const i18n = new VueI18n({
  locale: 'sl',
  messages: {
    en: {
      message: {
      }
    },
    sl: {
      message: {
      }
    }
  }
})

// check if cookie has any language
let cookie_lang = localStorage.getItem("locale")
if (cookie_lang != null)
    i18n.locale = localStorage.getItem("locale");


new Vue({
    el: '#app',
    i18n,
    router,
    store,
    vuetify: new Vuetify({
        icons: {
            iconfont: 'mdi', // default - only for display purposes
        },
    }),
    render: h => h(App)
});