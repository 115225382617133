<template>
  <v-app>

    <!-- pay attention to clipped and clipped left to make it work -->

    <NavBar></NavBar>

    <!-- show navigation only if logged in -->
    <NavigationDrawer v-if="status.loggedIn"></NavigationDrawer>
    <NavigationDrawerMobile v-if="$vuetify.breakpoint.mobile && status.loggedIn"></NavigationDrawerMobile>

    <v-main ref="app_vmain">
      <div v-if="$vuetify.breakpoint.mobile">
        <v-row>
          <v-col >
            <v-img :src="require('@/assets/inda_logo.png')"
             style="width: 120px; display: block; margin-right: auto; margin-left: auto; margin-top: 0.5em; margin-bottom:0.5em"></v-img>
          </v-col>
          <v-col v-if="user_data.company.supplier_label === 'DOMPLAN'">
            <v-img :src="require('@/assets/domplan_logo.jpg')"
             style="width: 150px; display: block; margin-right: auto; margin-left: auto; margin-top: 0.5em;"></v-img>
          </v-col>
          <v-col v-else-if="user_data.company.supplier_label === 'ENERGETIKA_LJUBLJANA'">
            <v-img :src="require('@/assets/energetika_logo.png')"
             style="width: 150px; display: block; margin-right: auto; margin-left: auto; margin-top: 0.5em;"></v-img>
          </v-col>
          <v-col v-else-if="user_data.company.supplier_label === 'GEORGE_WILSON_INDUSTRIES'">
            <v-img :src="require('@/assets/gwi_logo.jpg')"
             style="width: 85px; display: block; margin-right: auto; margin-left: auto; margin-top: 0.5em;"></v-img>
          </v-col>
        </v-row>
      </div>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <notifications group="amr" position="top right"/>
    <Snackbar></Snackbar>

    <!-- footer -->
    <v-footer
        fixed
        app
        style="background: rgb(119, 119, 119); color: white"
    >
      <v-col
          class="text-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Inda d.o.o.</strong> - v{{version}}
      </v-col>
    </v-footer>

  </v-app>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import NavBar from "@/components/NavBar";
import Snackbar from "@/components/Snackbar";
import NavigationDrawer from "@/components/site/NavigationDrawer";
import NavigationDrawerMobile from "@/components/site/NavigationDrawerMobile";
import router from './router/router.js';

import VERSION from '../../VERSION.txt'

// eslint-disable-next-line
let APP_VERSION = VERSION
console.log(`application version ${APP_VERSION}`)

export default {
  name: 'app',
  components: {NavigationDrawer, NavBar, Snackbar, NavigationDrawerMobile},
  created() {
    router.replace('/');
  },
  data() {
    return {
      width: 0,
      version: APP_VERSION
    }
  },
  mounted() {
    // application entry point
    this.getUserdata();
    this.$nextTick(() => {
      this.width = this.$refs.app_vmain.innerWidth;
    });
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    }),
    ...mapState('user', ['user_data', 'status'])
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      getUserdata: 'user/getUserData'
    })
  },
  watch: {
    $route() {
      // clear alert on location change
      this.clearAlert();
    }
  }
};
</script>


<style>

/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* scrollbar settings */
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
</style>

