<template>
  <div>
    <v-row>

      <!-- device info -->
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <div class="d-flex justify-space-between" style="width: 100%">
              <div>
                Info
              </div>
              <div>
                <v-btn
                    icon
                    color="red"
                    @click="refreshDevice()"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </div>
            </div>

          </v-card-title>
          <v-card-subtitle>
            {{ $t("basic_device_info") }}
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>{{ $t("Unique ID") }}</td>
                      <td>{{ device.uid }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("Consumption point") }}</td>
                      <td>{{ device.consumption_point }}</td>
                    </tr>
                    <tr v-if="user_data.company.setting_can_enter_gas_id">
                      <td>{{ $t("Gas ID") }}</td>
                      <td>{{ device.gas_uid }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("Supplier") }}</td>
                      <td>{{ device.supplier }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("Installed") }}</td>
                      <td>
                        <div v-if="device.installed">
                          <v-icon color="green">mdi-check-bold</v-icon>
                        </div>
                        <div v-else>
                          <v-icon color="red">mdi-do-not-disturb</v-icon>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("Configured") }}</td>
                      <td>
                        <div v-if="device.configured">
                          <v-icon color="green">mdi-check-bold</v-icon>
                        </div>
                        <div v-else>
                          <v-icon color="orange">mdi-autorenew</v-icon>
                          {{ $t("pending") }}
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("gas_initial_volume") }}</td>
                      <td>{{ device.gas_initval }} m³</td>
                    </tr>
                    <tr>
                      <td>{{ $t("gas_constant") }}</td>
                      <td>{{ device.gas_constant }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <!--<Chart></Chart>-->
          <!--<TrendLineChart></TrendLineChart>-->
          <VolumeBarChart :for-device="device.uid" :for-all-devices="false"></VolumeBarChart>

        </v-card>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-title class="">
                <td>{{ $t("month") }}</td>
              </v-card-title>
              <v-card-subtitle>{{ monthly_range }}</v-card-subtitle>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12">
                    <VolumeLineChartSimple v-bind:height=150 v-bind:num-days=30 :for-device="device.uid"
                                           :for-all-devices="false" ref="monthly_volume"></VolumeLineChartSimple>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h4">
                      {{ monthly_volume }} m³
                    </p>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card>
              <v-card-title>{{ $t("week") }}</v-card-title>
              <v-card-subtitle>{{ weekly_range }}</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <VolumeLineChartSimple v-bind:height=150 v-bind:num-days=7 :for-device="device.uid"
                                           :for-all-devices="false" ref="weekly_volume"></VolumeLineChartSimple>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h4">
                      {{ weekly_volume }} m³
                    </p>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card>
              <v-card-title>{{ $t("day") }}</v-card-title>
              <v-card-subtitle>{{ daily_range }}</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <VolumeLineChartSimple v-bind:height=150 v-bind:num-days=1 :for-device="device.uid"
                                           :for-all-devices="false" ref="daily_volume"></VolumeLineChartSimple>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-center text-h4">
                      {{ daily_volume }} m³
                    </p>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- REPORTS -->

      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t("report") }}
          </v-card-title>
          <v-card-subtitle>{{ $t("data_received_from_device") }}</v-card-subtitle>
          <v-card-text>
            <v-tabs>
              <v-tab>
                <v-icon left>
                  mdi-cup-water
                </v-icon>
                {{ $t("volume_events") }}
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-calendar-alert
                </v-icon>
                {{ $t("tamper_events") }}
              </v-tab>
              <v-tab>
                <v-icon left>
                  mdi-progress-upload
                </v-icon>
                {{ $t("load_profile") }}
              </v-tab>

              <v-tab>
                <v-icon left>
                  mdi-clipboard-list-outline
                </v-icon>
                {{ $t("event_log") }}
              </v-tab>

              <v-tab>
                <v-icon left>
                  mdi-history
                </v-icon>
                {{ $t("log") }}
              </v-tab>

              <v-tab-item>
                <AmrTable :selected_devices="[device.uid]"
                          :selected_dates="[]"
                          :selected_consumption_points="[]"
                          :selected_tabletype="1">
                </AmrTable>
              </v-tab-item>
              <v-tab-item>
                <AmrTable :selected_devices="[device.uid]"
                          :selected_dates="[]"
                          :selected_consumption_points="[]"
                          :selected_tabletype="2"></AmrTable>
              </v-tab-item>
              <v-tab-item>
                <AmrTable :selected_devices="[device.uid]"
                          :selected_dates="[]"
                          :selected_consumption_points="[]"
                          :selected_tabletype="3"></AmrTable>
              </v-tab-item>

              <v-tab-item>
                <AmrTable :selected_devices="[device.uid]"
                          :selected_dates="[]"
                          :selected_consumption_points="[]"
                          :selected_tabletype="5"></AmrTable>
              </v-tab-item>

              <v-tab-item>
                <AmrTable :selected_devices="[device.uid]"
                          :selected_dates="[]"
                          :selected_consumption_points="[]"
                          :selected_tabletype="6"></AmrTable>
              </v-tab-item>

            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AmrTable from "@/components/tables/AmrTable";
import common_translations from "@/_helpers/common_translations";
import VolumeBarChart from "@/components/charts/apex-charts/VolumeBarChart";
import VolumeLineChartSimple from "@/components/charts/apex-charts/VolumeLineChartSimple";
//import Chart from "@/components/Chart";

export default {
  name: "Device",
  components: {AmrTable, VolumeBarChart, VolumeLineChartSimple},
  data() {
    return {
      uid: this.$route.params.device_uid,
      daily_volume: 0,
      weekly_volume: 0,
      monthly_volume: 0,
      daily_range: "",
      weekly_range: "",
      monthly_range: ""
    }
  },
  computed: {
    ...mapState('amr', ['devices', 'refresh_cnt']),
    ...mapState('user', ['user_data']),
    device() {
      this.refresh_cnt;
      return this.devices.find(device => device.uid === this.uid);
    },
  },
  methods: {
    ...mapActions('amr', ['get_device']),
    refreshDevice() {
      // refresh particular device db entry
      this.get_device(this.device.id)
    }
  },
  mounted() {

    // now we watch for child graph change like that :/
    this.$watch(
        () => {
          return this.$refs.daily_volume.external_access.volume_sum
        },
        (val) => {
          this.daily_volume = val;
        });

    this.$watch(
        () => {
          return this.$refs.daily_volume.external_access.time_range
        },
        (val) => {
          this.daily_range = val;
        });

    this.$watch(
        () => {
          return this.$refs.weekly_volume.external_access.volume_sum
        },
        (val) => {
          this.weekly_volume = val;
        });

    this.$watch(
        () => {
          return this.$refs.weekly_volume.external_access.time_range
        },
        (val) => {
          this.weekly_range = val;
        });

    this.$watch(
        () => {
          return this.$refs.monthly_volume.external_access.volume_sum
        },
        (val) => {
          this.monthly_volume = val;
        });

    this.$watch(
        () => {
          return this.$refs.monthly_volume.external_access.time_range
        },
        (val) => {
          this.monthly_range = val;
        });
  },
  watch: {
    $route(to) {
      this.uid = to.params.device_uid;
    },
  },
  i18n: {
    messages: {
      sl: {
        tamper_events: "Izjemni dogodki",
        volume_events: "Skupni odčitki",
        load_profile: "Profil porabe",
        event_log: "Zgodovina dogodkov",
        log: "Zgodovina",
        install_events: "Inštalacijska sporočila",
        report: "Poročilo",
        data_received_from_device: "Prejeti podatki od naprave",
        basic_device_info: "Osnovne informacije o napravi",
        gas_initial_volume: "Začetna vrednost",
        month: "Mesec",
        week: "Teden",
        day: "Dan",
        pending: "Čakam potrditev...",
        gas_constant: "Vrednost impulza"
      },
      en: {
        tamper_events: "Tamper events",
        volume_events: "Volume events",
        load_profile: "Load profile",
        event_log: "Event log",
        log: "History",
        install_events: "Install events",
        report: "Report",
        data_received_from_device: "Data received from device",
        basic_device_info: "Basic device_info",
        gas_initial_volume: "Gas initial volume",
        month: "Month",
        week: "Week",
        day: "Day",
        pending: "Waiting for confirmation...",
        gas_constant: "Pulse count"
      },
    },
    sharedMessages: common_translations.joined_translations
  }
}
</script>

<style scoped>

</style>
