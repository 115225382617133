<template>
  <div>
    <div>
      {{ request.command }} - {{ $t(request.logical_name_desc.description) }}
    </div>
    <div class="text-subtitle-1">
      {{ $t('logical_name') }}: {{ request.logical_name }}
    </div>
    <div class="text-subtitle-1">
      <!-- display only numbers for attribute -->
      {{ $t('attribute') }}: {{ request.logical_name_desc.attribute.match(/\d/g).join("") }}
    </div>
  </div>
</template>

<script>
import common_translations from "@/_helpers/common_translations";

export default {
  name: "RequestSummary",
  props: ['request'],
  i18n: {
    messages: {
      sl: {
        logical_name: "Logično ime",
        attribute: "Atribut",
      },
      en: {
        logical_name: "Logical name",
        attribute: "Attribute",
      },
    },
    sharedMessages: common_translations.iec1107_translations
  }
}
</script>

<style scoped>

</style>