import Vue from 'vue';
import Router from 'vue-router';

import Login from '../login/Login.vue';
import Dashboard from '@/amr/views/Dashboard';
import Settings from "@/amr/views/Settings";
import Devices from "@/amr/views/Devices";
import Reports from "@/amr/views/Reports";
import Device from "@/components/Device";
import Iec1107 from "@/amr/views/Iec1107";

Vue.use(Router);


export const router = new Router({
    mode: 'abstract',
    routes: [
        // redirect for amr for now
        {path: '/', component: Dashboard, redirect: '/amr/dashboard'},
        {path: '/login', component: Login},
        {
            path: '/amr/dashboard',
            component: Dashboard
        },
        {
            path: '/amr/settings',
            component: Settings
        },
        {
            path: '/amr/devices',
            component: Devices
        },
        {
            path: '/amr/reports',
            component: Reports
        },
        {
            path: '/amr/iec1107',
            component: Iec1107
        },
        {
            path: '/amr/device/:device_uid',
            component: Device
        },
        // otherwise redirect to home
        {path: '*', redirect: '/'}
    ]
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page

    const publicPages = ['/login', '/register'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
})

export default router;
