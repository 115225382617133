<template>
    <div>
        <v-card>
            <v-list-item>
                <v-list-item-content>
                    <div class="overline mb-4">
                        <div>
                            <div class="d-inline">
                                {{ $t("tamper_events_stats") }}
                            </div>
                        </div>

                    </div>
                    <v-list-item-title class="headline mb-1">

                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar
                        tile
                        size="80"
                >
                    <v-icon color="red darken-1" x-large>mdi-list-status</v-icon>
                </v-list-item-avatar>
            </v-list-item>

            <div style="text-align: center" class="ma-1">
                <v-btn-toggle v-model="interval_btn_index" color="red darken-1">
                    <v-btn class="ma-1" @click="interval='day1'">{{ $t('day1')}}</v-btn>
                    <v-btn class="ma-1" @click="interval='days3'">{{ $t('day3')}}</v-btn>
                    <v-btn class="ma-1" @click="interval='days7'">{{ $t('day7')}}</v-btn>
                    <v-btn class="ma-1" @click="interval='days30'">{{ $t('day30')}}</v-btn>
                </v-btn-toggle>

            </div>


            <div v-if="tamper_history !== undefined">
                <v-expansion-panels class="">
                    <v-expansion-panel
                            v-for="(tamper, index) in tamper_history[interval]"
                            v-bind:key="`tamper_${index}`">
                        <v-expansion-panel-header>
                            <v-row dense>
                                <v-col cols="1" v-if="get_tamper_icon(parseInt(index)) !== ''">
                                    <v-icon>{{ get_tamper_icon(parseInt(index)) }}</v-icon>
                                </v-col>
                                <v-col cols="8" class="h6 font-weight-light mb-0">
                                    {{ $t(tamper.description) }}
                                </v-col>
                                <v-col cols="3" class="h5 font-weight-regular">
                                    {{ tamper.count }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table>
                                <tbody>
                                <tr
                                        v-for="(tamper_time) in tamper.tamper_list"
                                        v-bind:key="`tamper__list_${tamper_time}`"
                                >
                                    <td style="cursor: pointer" @click="handleClickRow(tamper_time.uid)">
                                        {{ tamper_time.uid }}
                                    </td>
                                    <td>{{ tamper_time.time }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

            </div>


        </v-card>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import common_translations from "@/_helpers/common_translations";
import icon_utils from "@/_helpers/icons";

export default {
    name: "TamperHistoryList",
    data() {
        return {
            interval: 'day1',
            interval_btn_index: 0
        }
    },
    computed: {
        ...mapState('amr', ['tamper_history', 'devices']),
    },
    methods: {
        get_tamper_icon(tamper_bit) {
            return icon_utils.get_tamper_icon(tamper_bit)
        },
        ...mapActions('amr', ['add_device_to_watch']),
        handleClickRow(device) {
            console.log(`Adding device ${device} to watch`);
            let device_db = this.devices.find(dev => dev.uid === device);
            this.add_device_to_watch(device_db);
            // go to device view
            this.$router.push({path: `/amr/device/${device_db.uid}`});
            // emit to navigation drawer there was a change
            this.$root.$emit('open_device', device_db.uid);
        },
    },
    i18n: {
        messages: {
            en: {
                tamper_events_stats: "TAMPER STATISTICS",
                day1: '1 Day',
                day3: '3 Days',
                day7: '7 Days',
                day30: '30 Days'

            },
            sl: {
                tamper_events_stats: "STATISTIKA IZJEMNIH DOGODKOV",
                day1: '1 Dan',
                day3: '3 Dni',
                day7: '7 Dni',
                day30: '30 Dni'
            },
        },
        sharedMessages: common_translations.tamper_descriptions
    }
}
</script>

<style scoped>

</style>