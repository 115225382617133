const state = {
    type: null,
    message: null,
    alert_cnt: 0,
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
        state.alert_cnt += 1;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        state.alert_cnt += 1;
    },
    clear(state) {
        state.type = null;
        state.message = "";
        state.alert_cnt = 0;
    }
};

const getters = {
  getAlertMessage: state => { return state.message },
  getAlertCnt: state => { return state.alert_cnt }
}

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export default alert;
