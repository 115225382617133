<template>
  <div>
    <!-- Device serial number -->
    <div class="d-flex" v-if="response.logical_name.includes('0-0:96.1.0') && response.attribute === 'A2'">
      <!-- error register mask -->
      <div class="mr-3 text-body-1 font-weight-medium">
        {{ $t('serial_number') }} :
      </div>
      <div>
        {{ response.data[0].value }}
      </div>
    </div>
    <!-- Error register 1 mask -->
    <div class="d-flex" v-else-if="response.logical_name.includes('7-0:97.98.1') && response.attribute === 'A2'">
      <!-- error register mask -->
      <div class="mr-3 text-body-1 font-weight-medium">
        {{ $t('mask') }} :
      </div>
      <div>
        {{ response.data[0].value }}
      </div>
    </div>
    <!-- Tilt shake sensitivity -->
    <div class="d-flex" v-else-if="response.logical_name.includes('0-0:128.3.26') && response.attribute === 'A2'">
      <!-- tilt shake sensitivity -->
      <div class="mr-3 text-body-1 font-weight-medium">
        {{ $t('sensitivity') }} [%] :
      </div>
      <div>
        {{ response.data[0].value }}
      </div>
    </div>
    <!-- pulse duration -->
    <div class="d-flex" v-else-if="response.logical_name.includes('0-0:128.3.61*255') && response.attribute === 'A2'">
      <!-- tilt shake sensitivity -->
      <div class="mr-3 text-body-1 font-weight-medium">
        {{ $t('pulse_duration') }} [ms] :
      </div>
      <div>
        {{ response.data[0].value }}
      </div>
    </div>
    <div class="mb-2" v-else-if="response.logical_name.includes('0-0:128.3.36') && response.attribute === 'A2'">
      <!-- temperature limits -->
      <div class="d-flex">
        <div class="mr-3 text-body-1 font-weight-medium">
          {{ $t('high_temperature_treshold') }} [<span>&#176;</span>C] :
        </div>
        <div>
          {{ response.data[0].value[0].value }}
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-3 text-body-1 font-weight-medium">
          {{ $t('low_temperature_treshold') }} [<span>&#176;</span>C] :
        </div>
        <div>
          {{ response.data[0].value[1].value }}
        </div>
      </div>
    </div>
    <div class="mb-2" v-else-if="response.logical_name.includes('0-0:99.98.0') && response.attribute === 'A2'">
      <!-- event log -->
      <div class="d-flex" v-for="i in response.data[0].length" v-bind:key="`event_log_${i}`">
        <div class="d-flex mr-2">
          <div class="mr-3 text-body-1 font-weight-medium">
            {{ $t('time') }} :
          </div>
          <div>
            {{ response.data[0].value[i - 1].value[0].value }}
          </div>
        </div>
        <div class="d-flex">
          <div class="mr-3 text-body-1 font-weight-medium">
            {{ $t('tamper_event') }} :
          </div>
          <div style="white-space: pre">
            {{ response.data[0].value[i - 1].value[1].value.map(element => $t(element)).join(' \r\n ') }}
          </div>
        </div>
      </div>

    </div>

    <div class="mb-2" v-else-if="response.logical_name.includes('7-0:99.1.0') && response.attribute === 'A2'">
      <!-- load profile -->
      <div class="d-flex" v-for="i in response.data[0].length" v-bind:key="`event_log_${i}`">
        <div class="d-flex mr-2">
          <div class="mr-3 text-body-1 font-weight-medium">
            {{ $t('time') }} :
          </div>
          <div>
            {{ response.data[0].value[i - 1].value[0].value }}
          </div>
        </div>
        <div class="d-flex mr-2">
          <div class="mr-3 text-body-1 font-weight-medium">
            {{ $t('status') }} :
          </div>
          <div>
            {{ response.data[0].value[i - 1].value[1].value }}
          </div>
        </div>
        <div class="d-flex">
          <div class="mr-3 text-body-1 font-weight-medium">
            {{ $t('volume') }} :
          </div>
          <div>
            {{ response.data[0].value[i - 1].value[2].value[0].value }}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex" v-else-if="response.logical_name.includes('7-0:99.1.0') && response.attribute === 'A4'">
      <div class="mr-3 text-body-1 font-weight-medium">
        {{ $t('capture_period') }} [s] :
      </div>
      <div>
        {{ response.data[0].value }}
      </div>
    </div>

    <div v-else-if="(response.logical_name.includes('0-1:25.9.0') ||
                     response.logical_name.includes('0-4:25.9.0') ||
                     response.logical_name.includes('0-7:25.9.0') ||
                     response.logical_name.includes('0-8:25.9.0')) && response.attribute === 'A3'">
      <!-- push setup -->
      <div class="d-flex">
        <div class="mr-3 text-body-1 font-weight-medium">
          {{ $t('transport_type') }} (0-TCP, 1-UDP, 4-SMS) :
        </div>
        <div>
          {{ response.data[0].value[0].value }}
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-3 text-body-1 font-weight-medium">
          {{ $t('destination') }} :
        </div>
        <div>
          {{ response.data[0].value[1].readable_value }}
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-3 text-body-1 font-weight-medium">
          {{ $t('message_type') }} (0-AXDR encoded, 1-XML encoded, 128-Inda encoded) :
        </div>
        <div>
          {{ response.data[0].value[2].value }}
        </div>
      </div>
    </div>

    <div class="d-flex" v-else-if="(response.logical_name.includes('0-1:25.9.0') ||
                                    response.logical_name.includes('0-4:25.9.0') ||
                                    response.logical_name.includes('0-7:25.9.0') ||
                                    response.logical_name.includes('0-8:25.9.0')) && response.attribute === 'A6'">
      <div class="mr-3 text-body-1 font-weight-medium">
        {{ $t('num_retries') }} :
      </div>
      <div>
        {{ response.data[0].value }}
      </div>
    </div>

    <div class="d-flex" v-else-if="(response.logical_name.includes('0-1:25.9.0') ||
                                    response.logical_name.includes('0-4:25.9.0') ||
                                    response.logical_name.includes('0-7:25.9.0') ||
                                    response.logical_name.includes('0-8:25.9.0')) && response.attribute === 'M1'">
      <!-- execute push method -->
      <!-- no data -->
    </div>

    <div class="" v-else-if="response.logical_name.includes('0-0:15.0.4') && response.attribute === 'A4'">
      <!-- event log -->
      <div class="ma-2" v-for="i in response.data[0].length" v-bind:key="`execution_time_${i}`">
        <div class="">
          <div class="d-flex">
            <div class="text-body-1 font-weight-medium">
              {{ $t('date') }} / {{ $t('time') }} :
            </div>
            <div class="ml-1">
              <div class="">YYYYMMDDWW / hhmmsshs</div>
            </div>
          </div>
          <div>

            <div class="d-flex">
              <v-icon>mdi-hexadecimal</v-icon>
              <div class="ml-2">{{ response.data[0].value[i - 1].value[1].value }} / {{
                  response.data[0].value[i - 1].value[0].value
                }}
              </div>
            </div>

            <div class="d-flex">
              <v-icon>mdi-decimal</v-icon>
              <div class="ml-2">
                {{ hex_date_interval_to_string(response.data[0].value[i - 1].value[1].value) }} /
                {{ hex_time_to_string(response.data[0].value[i - 1].value[0].value) }}
              </div>
            </div>

            <div class="d-flex">

              <v-icon>mdi-format-color-text</v-icon>
              <div class="ml-2">
                {{
                  dynamic_translate(hex_date_time_to_description(response.data[0].value[i - 1].value[1].value, response.data[0].value[i - 1].value[0].value))
                }}
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <!-- unkown -->
      {{ response.data }}
    </div>

  </div>
</template>

<script>
import common_translations from "@/_helpers/common_translations";
import {
  hex_date_to_string,
  hex_time_to_string,
  hex_date_interval_to_string,
  hex_date_time_to_description
} from "@/_helpers/iec_utilities";
import {mapState} from "vuex";

export default {
  name: "SimpleResponse",
  props: ['response'],
  i18n: {
    sharedMessages: common_translations.joined_translations
  },
  computed: {
    ...mapState('user', ['language']),
  },
  methods: {
    hex_date_to_string(hex_date) {
      return hex_date_to_string(hex_date);
    },
    hex_time_to_string(hex_time) {
      return hex_time_to_string(hex_time)
    },
    hex_date_interval_to_string(hex_date) {
      return hex_date_interval_to_string(hex_date)
    },
    hex_date_time_to_description(hex_date, hex_time) {
      return hex_date_time_to_description(hex_date, hex_time, this.language)
    },
    dynamic_translate(item) {
      // console.log(`translating to ${this.language}`, item)
      // console.log(common_translations.history_translations[this.language]);
      let translated = item
      for (let message in common_translations.iec_translations[this.language]) {
        let translation = common_translations.iec_translations[this.language][message]
        let position = item.search(message)
        if (position >= 0) {
          // console.log(`Translation found ${message} ${translation}`)
          translated = translated.replace(message, translation)
        }
      }
      // console.log("translated ", translated)
      return translated;
    }
  }
}
</script>

<style scoped>

</style>