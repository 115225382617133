<template>
  <v-container @keydown.enter="handleSubmit()">
    <v-row>
      <v-col style="display: flex; justify-content: center">
        <v-form
            class="justify-center"
            style="max-width: 300px"
            ref="form"
            v-model="valid"
            lazy-validation

        >
          <v-text-field
              v-model="username"
              label="Username"
              :rules="rules"
              required
          >
          </v-text-field>

          <v-text-field
              v-model="password"
              label="Password"
              :rules="rules"
              required
              :type="'password'"
              area-
          >
          </v-text-field>

          <v-btn
              @click="handleSubmit()"
              style="width: 100%"
          >
            Login
          </v-btn>


        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      valid: true,
      rules: [
        v => !!v || 'This field is required'
      ],
    }
  },
  computed: {
    ...mapState('user', ['status'])
  },
  created() {
    // reset login status
    this.logout();
  },
  methods: {
    ...mapActions('user', ['login', 'logout']),
    handleSubmit() {
      this.validate();
      this.submitted = true;
      const {username, password} = this;
      if (username && password) {
        this.login({username, password})
      }
    },
    validate() {
      this.$refs.form.validate()
    },
  }
}
</script>

<style scoped>

</style>
