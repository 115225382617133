<template>
  <div>
    <div class="ma-2 mb-6" v-if="cosem_models.length > 0">
      <v-btn @click="openModal()">
        <v-icon>
          mdi-plus
        </v-icon>
        {{ $t('add_new_request') }}
      </v-btn>
    </div>
    <div class="ma-2">
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, index) in iec1007requests"
                           v-bind:key="`˙iec_request_${index}`"
                           class="ma-2"
        >

          <v-expansion-panel-header>
            <div class="d-flex justify-space-around">
              <v-row>
                <v-col md="3" cols="12">
                  {{ item.uid }}
                </v-col>
                <v-col md="3" cols="12">
                  <v-row no-gutters class="mb-1">
                    <v-col sm="6" md="3">{{ $t('created') }}:</v-col>
                    <v-col>{{ item.request_created_time }}</v-col>
                  </v-row>
                  <v-row no-gutters class="mb-1">
                    <v-col sm="6" md="3">{{ $t('sent') }}:</v-col>
                    <v-col>{{ item.request_sent_time }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="6" md="3">{{ $t('received') }}:</v-col>
                    <v-col>{{ item.response_received_time }}</v-col>
                  </v-row>
                  <v-row no-gutters v-if="item.created_by">
                    <v-col sm="6" md="3">{{ $t('created_by') }}:</v-col>
                    <v-col>{{ item.created_by }}</v-col>
                  </v-row>
                  <v-row no-gutters v-if="item.response_status === 'WAITING_FOR_RECEIVED_DATA'">
                    <div class="d-flex justify-center align-center ma-2">
                      <v-progress-circular
                          indeterminate
                          color="green"
                          class="ma-2"
                          width="3"
                          size="20"
                      ></v-progress-circular>
                      <div>{{ $t('receive_in_progress') }}</div>
                    </div>
                  </v-row>
                </v-col>

                <v-col md="3" cols="12">
                  {{
                    item.parsed_request_message.map(
                        e => e.response_status !== -1 ? $t(e.logical_name_desc.description) + ` (${(e.command !== undefined ? e.command : "")})` : ''
                    ).join('\n')
                  }}
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>

            <v-card class="ma-2" v-for="(request_element, element) in iec1007requests[index].parsed_request_message"
                    v-bind:key="`iec_request_${index}_${element}`">
              <!--<v-card-title>
                {{ request_element.command }}-{{ request_element.logical_name_desc.description }}
              </v-card-title>-->
              <v-card-text v-if="request_element.response_status !== -1" style="max-height: 400px; overflow-y: auto">
                <v-row>
                  <v-col cols="12" md="4" class="text-h6">
                    <RequestSummary :request="request_element"></RequestSummary>
                  </v-col>

                  <v-col cols="12" md="6">
                    <!--
                      READ COMMANDS
                    -->
                    <div class="text-body-1"
                         v-if="request_element.command === 'R9' && requestHasResponse(index, element)">
                      <SimpleResponse :response="getResponse(index, element)"></SimpleResponse>

                    </div>
                    <!--
                      WRITE COMMANDS
                    -->
                    <div class="text-body-1"
                         v-if="request_element.command === 'W9' || request_element.command === 'E9'">
                      <SimpleResponse :response="request_element"></SimpleResponse>
                    </div>

                  </v-col>
                  <v-col cols="12" md="2" v-if="iec1007requests[index].parsed_response_message !== null &&
                                        iec1007requests[index].parsed_response_message[element] !== undefined
                                      && iec1007requests[index].parsed_response_message[element].response_status === 0">
                    <v-icon color="green">mdi-check-bold</v-icon>
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-text v-else style="max-height: 400px; overflow-y: auto">
                <!-- response error -->
                <v-icon color="red" class="mr-2">mdi-alert</v-icon>
                {{ $t('parsing_error') }}
              </v-card-text>
            </v-card>

            <v-divider></v-divider>

            <div>
              <v-btn class="error"
                     @click="deleteRequest(item.id)"
              >{{ $t('delete_request') }}
                <v-icon
                    right
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div>
      <!-- iec request editor -->
      <IEC1107RequestEditor ref="request_editor"></IEC1107RequestEditor>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RequestSummary from "@/components/iec1107/RequestSummary";
import SimpleResponse from "@/components/iec1107/responses/SimpleResponse";
import IEC1107RequestEditor from "@/components/iec1107/IEC1107RequestEditor";
import common_translations from "@/_helpers/common_translations";
import {mapActions, mapState} from "vuex";

export default {
  name: "Iec1107",
  components: {IEC1107RequestEditor, SimpleResponse, RequestSummary},
  data() {
    return {
      iec1007requests: [],
      cosem_models: [],

    }
  },
  mounted() {
    this.$root.$on('refreshIEC1107Requests', this.getData);
    this.getData()
  },
  computed: {
    ...mapState('amr', ['cosem_models']),
  },
  methods: {
    ...mapActions('amr', ['get_devices']),
    getData() {
      return axios.get('/api/amr/iec1107', {
        params: {},
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            console.log("Got iec1007 requests");
            this.iec1007requests = response.data.requests;
            this.cosem_models = response.data.cosem_models;
            this.$store.commit("amr/cosem_models", this.cosem_models);
          })
    },
    deleteRequest(requestId) {
      return axios.post('/api/amr/iec1107',
          {
            action: 'delete_request',
            id: requestId
          }
          ,
          {
            params: {},
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(() => {
            console.log("Got iec1007 requests");
            this.$notify({
              group: 'amr',
              title: 'IEC1107 request',
              text: 'Deleted sucesfully',
              type: 'success'
            });
            this.getData();
            this.get_devices();
          })
    },
    requestHasResponse(request_index, request_response) {
      if (this.iec1007requests[request_index].parsed_response_message === null)
        return false
      return this.iec1007requests[request_index].parsed_response_message[request_response] !== undefined
    },
    getResponse(request_index, request_response) {
      return this.iec1007requests[request_index].parsed_response_message[request_response]
    },
    openModal() {
      this.$refs.request_editor.openModal();
    }
  },
  i18n: {
    messages: {
      sl: {
        add_new_request: "Dodaj novo zahtevo",
        delete_request: "Izbriši zahtevo",
        created: "Ustvarjen",
        sent: "Poslan",
        received: "Prejet",
        created_by: "Ustvaril",
        parsing_error: "Napaka obdelave sporočila",
        receive_in_progress: "Prejemanje sporočila..."
      },
      en: {
        add_new_request: "Add new request",
        delete_request: "Delete request",
        created: "Created",
        sent: "Sent",
        received: "Received",
        created_by: "Created by",
        parsing_error: "Message parsing error",
        receive_in_progress: "Receiving message..."
      },
    },
    sharedMessages: common_translations.iec1107_translations
  }
}
</script>

<style scoped>

</style>