<template>
  <div>
    <v-row no-gutters>
      <v-col
          cols="12"
          v-for="element in num_elements"
          v-bind:key = "`sending_interval_${element}`"
      >
        <TimeSendingInterval @valid-event="element_valid" class="ma-2" :index="element-1" :values="values"></TimeSendingInterval>
      </v-col>
    </v-row>
    <v-row style="text-align: center">
      <v-col>
        <v-btn @click="addInterval">Add Interval</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimeSendingInterval from "@/components/iec1107/requests/specials/TimeSendingInterval";

export default {
  components: {TimeSendingInterval},
  props: ['values'],
  name: "TimeSendingIntervals",
  data() {
    return {
      num_elements: 1,
      max_elements: 4,
      elements_valid: [false]
    }
  },
  methods: {
    addInterval() {
      if(this.num_elements === this.max_elements)
        return
      this.num_elements += 1
      this.elements_valid[this.num_elements-1] = false
    },
    element_valid(valid, index) {
      this.elements_valid[index] = valid
      this.all_elements_valid = true
      for(let i=0; i<this.elements_valid.length; i++)
      {
        if (this.elements_valid[i] !== true)
          this.all_elements_valid = false
      }
      // console.log('all valid ', this.all_elements_valid)
      this.$emit('request-element-valid', this.all_elements_valid)
    }
  }

}
</script>

<style scoped>

</style>