let icon_utils = {
    get_tamper_icon(tamper_bit) {
        if (tamper_bit === 0)
            return "mdi-battery-charging-wireless-alert"
        if (tamper_bit === 1)
            return "mdi-battery-minus-outline"
        if (tamper_bit === 6)
            return "mdi-magnet-on"
        if (tamper_bit === 7)
            return "mdi-thermometer-alert"
        if (tamper_bit === 8)
            return "mdi-wrench-clock"
        if (tamper_bit === 10)
            return "mdi-dog"
        if (tamper_bit === 11)
            return "mdi-usb-flash-drive"
        if (tamper_bit === 20)
            return "mdi-motion-sensor"
        if (tamper_bit === 23)
            return "mdi-battery-outline"
        if (tamper_bit === 25)
            return "mdi-memory"
        if (tamper_bit === 28)
            return "mdi-cellphone-cog"
        return "";
    }
}

export default icon_utils