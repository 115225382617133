<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <div style="text-align: center">
            <div>{{ $t("year") }}</div>
            <input
                ref="year_ref"
                @keyup="updateValues()"
                style="width: 80px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="year_ref_dec"></div>
          </div>
          <div style="text-align: center" class="ml-2">
            <div>{{ $t("month") }}</div>
            <input
                @keyup="updateValues()"
                ref="month_ref"
                style="width: 80px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="month_ref_dec"></div>
          </div>
          <div style="text-align: center" class="ml-2">
            <div style="font-size: 12px">{{ $t("day_of_month") }}</div>
            <input
                @keyup="updateValues()"
                ref="day_of_month_ref"
                style="width: 80px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="day_of_month_ref_dec"></div>
          </div>
          <div style="text-align: center" class="ml-2">
            <div style="font-size: 12px">{{ $t("day_of_week") }}</div>
            <input
                @keyup="updateValues()"
                ref="day_of_week_ref"
                style="width: 80px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="day_of_week_ref_dec"></div>
          </div>

          <div style="text-align: center" class="ml-3">
            <div>{{ $t("hour") }}</div>
            <input
                @keyup="updateValues()"
                ref="hour_ref"
                style="width: 60px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="hour_ref_dec"></div>
          </div>
          <div style="text-align: center" class="ml-3">
            <div>{{ $t("minute") }}</div>
            <input
                @keyup="updateValues()"
                ref="minute_ref"
                style="width: 60px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="minute_ref_dec"></div>
          </div>
          <div style="text-align: center" class="ml-3">
            <div>{{ $t("second") }}</div>
            <input
                @keyup="updateValues()"
                ref="second_ref"
                style="width: 60px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="second_ref_dec"></div>
          </div>
          <div style="text-align: center" class="ml-3">
            <div>{{ $t("hundreths") }}</div>
            <input
                @keyup="updateValues()"
                disabled
                ref="hundreths_ref"
                style="width: 60px; text-align: center; font-size: 20px; height: 40px; border: 1px solid black"
            >
            <div style="font-size: 18px; margin-top: 5px" ref="hundreths_ref_dec"></div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <div>
          {{ description }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {hex_date_time_to_description} from "@/_helpers/iec_utilities";
import {mapState} from "vuex";
import common_translations from "@/_helpers/common_translations";

export default {
  props: ['values', 'index'],
  name: "TimeSendingInterval",
  data() {
    return {
      description: '',
      date_string: '',
      time_string: '',
    }
  },
  computed: {
    ...mapState('user', ['language']),
  },
  mounted() {
    this.values[2 * this.index+1] = 'FFFFFFFFFF'
    this.values[2 * this.index] = 'FF000000'
    this.$refs.year_ref.value = 'FFFF'
    this.$refs.month_ref.value = 'FF'
    this.$refs.day_of_month_ref.value = 'FF'
    this.$refs.day_of_week_ref.value = 'FF'
    this.$refs.hour_ref.value = 'FF'
    this.$refs.minute_ref.value = '00'
    this.$refs.second_ref.value = '00'
    this.$refs.hundreths_ref.value = '00'
    this.updateValues();
  },
  methods: {
    updateValues() {
      this.$refs.year_ref_dec.innerText = Number("0x" + this.$refs.year_ref.value).toString()
      this.$refs.month_ref_dec.innerText = Number("0x" + this.$refs.month_ref.value).toString()
      this.$refs.day_of_month_ref_dec.innerText = Number("0x" + this.$refs.day_of_month_ref.value).toString()
      this.$refs.day_of_week_ref_dec.innerText = Number("0x" + this.$refs.day_of_week_ref.value).toString()

      // lets handle hours now specially like instructed by dušan
      let new_hour = Number("0x" + this.$refs.hour_ref.value);
      if (new_hour >= 0xF0 && new_hour < 0xFF) {
        new_hour = new_hour & 0xF // just first nibble
      }
      this.$refs.hour_ref_dec.innerText = Number("0x" + new_hour.toString(16)).toString()

      this.$refs.minute_ref_dec.innerText = Number("0x" + this.$refs.minute_ref.value).toString()
      this.$refs.second_ref_dec.innerText = Number("0x" + this.$refs.second_ref.value).toString()
      this.$refs.hundreths_ref_dec.innerText = Number("0x" + this.$refs.hundreths_ref.value).toString()
      this.updateDescription()
    },
    updateDescription() {
      this.date_string = this.$refs.year_ref.value +
          this.$refs.month_ref.value +
          this.$refs.day_of_month_ref.value +
          this.$refs.day_of_week_ref.value
      this.time_string = this.$refs.hour_ref.value +
          this.$refs.minute_ref.value +
          this.$refs.second_ref.value +
          this.$refs.hundreths_ref.value
      if (this.date_string.length !== 10 || this.time_string.length !== 8)
      {
        this.description = '/'
        this.$emit('valid-event', false, this.index)
      }
      else
      {
        this.values[2 * this.index] = this.time_string
        this.values[2 * this.index+1] = this.date_string
        this.$emit('valid-event', true, this.index)
        this.description = hex_date_time_to_description(this.date_string, this.time_string, this.language)
      }
    }
  },
  i18n: {
    sharedMessages: common_translations.iec1106_common
  }
}
</script>

<style scoped>

</style>