// import config from 'config';
// import authHeader from '../_helpers/auth_header';
import axios from 'axios';

export const amrService = {
    get_device_stats,
    get_settings,
    get_alarms,
    get_devices,
    get_device,
    get_tamper_history
};

function get_device_stats() {
    console.log('Getting device statistics');

    return axios.get(`/api/amr/statistics`, {
        headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    })
        .then(function (response) {
            console.log(response);
            return Promise.resolve(response.data);
        })
}

function get_settings() {
    console.log('Getting settings');

    return axios.get(`/api/amr/settings`, {
        headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    })
        .then(function (response) {
            console.log(response);
            return Promise.resolve(response.data);
        })
}

function get_alarms() {
    console.log('Getting settings');

    return axios.get(`/api/amr/alarms`, {
        headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    })
        .then(function (response) {
            console.log(response);
            return Promise.resolve(response.data);
        })
}

function get_devices() {
    console.log('Getting devices');

    return axios.get(`/api/amr/devices`, {
        headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    })
        .then(function (response) {
            console.log(response);
            return Promise.resolve(response.data);
        })
}

function get_device(device_pk) {
    console.log('Getting device ', device_pk);

    return axios.get(`/api/amr/device/${device_pk}`, {
        headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    })
        .then(function (response) {
            console.log(response);
            return Promise.resolve(response.data);
        })
}

function get_tamper_history() {
    console.log('Getting devices');

    return axios.get(`/api/amr/graph/tamper_history`, {
        headers: {
            Authorization: `Token ${localStorage.getItem('token')}`
        }
    })
        .then(function (response) {
            console.log(response);
            return Promise.resolve(response.data);
        })
}

export default amrService;
