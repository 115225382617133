let tamper_descriptions = {
    sl: {
        'Battery low': 'Nizka napetost baterije',
        'Battery removed': 'Odstranjena baterija',
        'Calibration error': 'Napaka kalibracije',
        'Magnet detected': 'Zaznan magnet',
        'Empty battery': 'Prazna baterija',
        'High battery consumption': 'Visoka poraba baterije',
        'High flow detected': 'Zaznan visok pretok',
        'Reset required': 'Zahtevan ponovni zagon',
        'Temperature out of range': 'Temperatura izven območja',
        'Movement detected': 'Zaznano gibanje',
        'RTC error - oscilator fail': 'Napaka ure - čas ponastavljen',
        'Reverse flow': 'Vzvratni tok',
        'External flash error': 'Napaka trajnega pomnilnika',
        'Deinstall': 'Deinštalacija',
        'Watchdog': 'Nepredviden ponovni zagon',
        'UMI error': 'Napaka UMI modula'
    },
}

let table_headers = {
    sl: {
        'Search': 'Išči',
        'Unique ID': 'Serijska številka',
        'Device ID': 'Serijska številka',
        'Consumption point': 'Odjemno mesto',
        "Gas ID": "Številka plinomera",
        "Gas initial val": "Začetno stanje",
        "Supplier": "Podjetje",
        "Pulse count": "Vrednost impulza",
        "Installed": "Inštaliran",
        "Configured": "Konfiguriran",
        "Installed on": "Datum inštalacije",
        "Time": "Čas",
        "Volume": "Odčitek",
        "Tamper": "Izjemni dogodek",
        "Area": "Koda omrežja",
        "Measurements": "Meritve",
        "Counts": "Število pulzov"
    }
}

let iec1107_translations = {
    sl: {
        "Error register 1 mask": "Maska registra dogodkov 1",
        "Tilt shake sensitivity": "Občutljivost senzorja premika",
        "Temperature limits": "Mejne vrednosti temperature",
        "Event log buffer": "Pomnilnik dnevnika dogodkov",
        "Load profile buffer": "Pomnilnik profila porabe",
        "Load profile period": "Perioda profila porabe",
        "Push on interval - Send destination and method": "Intervalno sporočilo - Cilj in metoda pošiljanja",
        "Push on interval - Number of retries": "Intervalno sporočilo - Število ponovitev",
        "Push on interval - Push method": "Intervalno sporočilo - Metoda pošlji sporočilo",
        "Push on alarm - Send destination and method": "Alarm sporočilo - Cilj in metoda pošiljanja",
        "Push on alarm - Number of retries": "Alarm sporočilo - Število ponovitev",
        "Push on alarm - Push method": "Alarm sporočilo - Metoda pošlji sporočilo",
        "Push on installation - Send destination and method": "Inštalacijsko sporočilo - Cilj in metoda pošiljanja",
        "Push on installation - Number of retries": "Inštalacijsko sporočilo - Število ponovitev",
        "Push on installation - Push method": "Inštalacijsko sporočilo - Metoda pošlji sporočilo",
        "Push on installation 1 - Send destination and method": "Inštalacijsko sporočilo 1 - Cilj in metoda pošiljanja",
        "Push on installation 1 - Number of retries": "Inštalacijsko sporočilo 1 - Število ponovitev",
        "Push on installation 1 - Push method": "Inštalacijsko sporočilo 1 - Metoda pošlji sporočilo",
        "Execution time": "Časovni termini pošiljanja",
        "Unkown": "Neprepoznan",
        "Pulse duration": "Dolžina pulza",
        "Device serial number": "Serijska številka naprave"
    }
}

let iec1106_common = {
    sl: {
        time: "Čas",
        date: "Datum",
        transport_type: "Način pošiljanja",
        destination: "Cilj",
        message_type: "Tip sporočila",
        num_retries: "Število ponovitev",
        capture_period: "Interval zajemanja",
        volume: "Volumen",
        high_temperature_treshold: "Zgornja mejna vrednost",
        low_temperature_treshold: "Spodnja mejna vrednost",
        sensitivity: "Občutljivost",
        mask: "Maska",
        status: "Status",
        tamper_event: 'Izjemni dogodek',
        year: 'Leto',
        month: 'Mesec',
        day_of_month: 'Dan v mesecu',
        day_of_week: 'Dan v tednu',
        hour: 'Ura',
        minute: 'Minuta',
        second: 'Sekunda',
        hundreths: 'Stotinke',
        pulse_duration: "Dolžina pulza",
        serial_number: "Serijska številka"
    },
    en: {
        time: "Time",
        date: "Date",
        transport_type: "Transport type",
        destination: "Destination",
        message_type: "Message type",
        num_retries: "Number of retries",
        capture_period: "Capture period",
        volume: "Volume",
        high_temperature_treshold: "High temperature treshold",
        low_temperature_treshold: "Low temperature treshold",
        sensitivity: "Sensitivity",
        mask: "Mask",
        status: "Status",
        tamper_event: 'Tamper event',
        year: 'Year',
        month: 'Months',
        day_of_month: 'Day of month',
        day_of_week: 'Day of week',
        hour: 'Hour',
        minute: 'Minute',
        second: 'Second',
        hundreths: 'Hundreths',
        pulse_duration: "Pulse duration",
        serial_number: "Serial number"
    },
}

let history_translations = {
    sl: {
        'Device state changed to': 'Status naprave spremenjen na',
        'Gas initial value changed from': 'Začetno stanje števca spremenjeno iz',
        'to': 'na',
        'Installed changed': 'Inštaliran spremenjen',
        'through API': 'preko API'
    },
}

let iec_translations = {
    sl: {
        'repeats': 'ponovi se',
        'every day': 'vsak dan',
        'at': 'ob',
        'hourly': 'vsako uro',
        'January': 'Januar'
    },
}

let joined_common = {
    sl: Object.assign({},
        tamper_descriptions.sl,
        table_headers.sl,
        iec1107_translations.sl,
        iec1106_common.sl,
        history_translations.sl,
        iec1107_translations.sl),
    en: Object.assign({},
        tamper_descriptions.en,
        table_headers.en,
        iec1107_translations.en,
        iec1106_common.en,
        history_translations.en,
        iec1107_translations.en)
}

export const common_translations = {
    tamper_descriptions: tamper_descriptions,
    table_headers: table_headers,
    iec1107_translations: iec1107_translations,
    iec1106_common: iec1106_common,
    history_translations: history_translations,
    iec_translations: iec_translations,
    joined_translations: joined_common
};

export default common_translations;
