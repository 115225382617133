<template>
  <div>
    <div v-if="!data_loaded">
      <v-progress-linear
          indeterminate
          color="red"
      ></v-progress-linear>
    </div>
    <div>
      <apexchart ref="chart" type="bar" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
    <!--
    <div v-show="!data_loaded">
      <v-container style="height: 300px !important"
                   class="mx-auto"
                   type="card"
                   fill-height
      >
        <v-row align="center" justify="center">
          <v-progress-circular
              indeterminate
              :size="50"
              color="rgb(237,9,9)"
          ></v-progress-circular>
        </v-row>

      </v-container>
    </div>-->
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "VolumeBarChart",
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.$nextTick(() => {
      this.getChart();
    })
  },
  mounted() {

  },
  watch: {
    forDevice() {
      // monitor prop and refresh if changes!
      this.getChart();
      this.chartOptions.tooltip.x.format = "dd MMM";
      this.chartOptions.xaxis.labels.format = "dd MMM";
    },
    data_loaded() {
      console.log(this.$refs, this.language)
      this.$refs.chart.setLocale(this.language);
    }
  },
  computed: {
    ...mapState('user', ['language']),
  },

  data() {
    return {
      data_loaded: false,
      simulate: false,
      series: [{
        data: [],
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 300,
          defaultLocale: 'en',
          locales: [{
            name: 'en',
            options: {
              months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              toolbar: {
                selection: 'Selection',
                selectionZoom: 'Selection Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
              }
            }
          },
            {
              name: 'sl',
              options: {
                months: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
                days: ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'],
                shortDays: ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'],
                toolbar: {
                  exportToSVG: 'Prenesi SVG',
                  exportToPNG: 'Prenesi PNG',
                  exportToCSV: 'Prenesi CSV',
                  menu: 'Meni',
                  selection: 'Izbor',
                  selectionZoom: 'Izberi območje',
                  zoomIn: 'Povečaj',
                  zoomOut: 'Pomanjšaj',
                  pan: 'Pomikanje',
                  reset: 'Resetiraj',
                }
              }
            }],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: '12px',
            fontStyle: 'Roboto'
          }
        },
        title: {
          text: this.$t("volume_history"),
          align: 'left',
        },
        stroke: {
          show: true,
          width: 1,
          curve: 'smooth',
          colors: ['#777777']
        },
        xaxis: {
          categories: [],
          type: 'datetime',
          labels: {
            show: true,
            datetimeUTC: false,
          }
        },
        colors: ['#ED0909'],
        yaxis: {
          show: false
        }
      },
    }
  },
  props: {
    forAllDevices: Boolean,
    forDevice: String
  },
  methods: {
    getChart() {
      this.data_loaded = false
      return axios.get('/api/amr/graph/volume_history', {
        params: {
          forAllDevices: this.forAllDevices,
          forDevice: this.forDevice,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            console.log("Got bar chart data");
            console.log(response);

            let time = []
            let volume = []

            if (!this.simulate) {
              time = response.data.time_labels;
              volume = response.data.volume
            } else {
              time = [100, 200, 300]
              volume = [1.0, 2.0, 3.0]
            }

            let show_y_axis = false
            for(let i = 0; i < volume.length; i++) {
              if(volume[i] !== null) {
                show_y_axis = true;
                break;
              }
            }

            this.chartOptions.yaxis.show = show_y_axis
            this.chartOptions.xaxis.categories = time;

            //this.x_data = response.data.volume;
            this.series = [];
            this.series.push({
              data: volume,
              name: this.$t('volume')
            })

            // this was commented out since charts were not opening in the first show
            this.$refs.chart.updateSeries([{
              data: response.data.volume,
              name: this.$t('volume')
            }]);
            // refresh chart on loading!
            this.data_loaded = true;
            this.$refs.chart.refresh();

          })
    }
  },
  i18n: {
    messages: {
      en: {
        volume_history: "VOLUME HISTORY",
        volume: 'Volume [m³]'
      },
      sl: {
        volume_history: "ZGODOVINA PORABE",
        volume: 'Odčitek [m³]'
      },
    }
  }
}
</script>

<style>
.apexcharts-title-text {
  font-family: Roboto, sans-serif !important;
  font-size: .75rem !important;
  font-weight: 500;
  letter-spacing: .1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
}

.apexcharts-xaxis-label {
  font-family: Roboto, sans-serif !important;
  font-size: .65rem !important;
  font-weight: 500;
  letter-spacing: .0666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
}

</style>