<template>
  <div>
    <apexchart ref="chart" type="line" :height="height" :options="chartOptions" :series="series"></apexchart>
    <!--
    <template v-if="data_loaded">

    </template>
    <template v-else>
      <v-container style="height: 300px !important"
                   v-bind:style="{ height: height + 'px',}"
                   class="mx-auto"
                   type="card"
                   fill-height
      >
        <v-row align="center" justify="center">
          <v-progress-circular
              indeterminate
              :size="50"
              color="rgb(237,9,9)"
          ></v-progress-circular>
        </v-row>

      </v-container>
    </template>
    -->
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "VolumeLineChartSimple",
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.getChart();

    // setup chart options
    if (this.numDays === 1) {
      this.chartOptions.tooltip.x.format = "dd-MMM HH:mm";
      this.chartOptions.xaxis.labels.format = "HH:mm";
    } else {
      this.chartOptions.tooltip.x.format = "dd MMM";
      this.chartOptions.xaxis.labels.format = "dd MMM";
    }
  },
  watch: {
    forDevice() {
      // monitor prop and refresh if changes!
      this.getChart();
    }
  },
  computed: {
    ...mapState('user', ['language']),
  },
  data() {
    return {
      data_loaded: false,
      external_access: {
        volume_sum: 0,
        start_time: "",
        end_time: "",
        time_range: "",
        day: ""
      },
      series: [{
        data: [],
      }],

      chartOptions: {
        chart: {
          type: 'line',
          height: 300,
          defaultLocale: 'en',
          locales: [{
            name: 'en',
            options: {
              months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              toolbar: {
                selection: 'Selection',
                selectionZoom: 'Selection Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
              }
            }
          },
            {
              name: 'sl',
              options: {
                months: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
                days: ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'],
                shortDays: ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'],
                toolbar: {
                  exportToSVG: 'Prenesi SVG',
                  exportToPNG: 'Prenesi PNG',
                  exportToCSV: 'Prenesi CSV',
                  menu: 'Meni',
                  selection: 'Izbor',
                  selectionZoom: 'Izberi območje',
                  zoomIn: 'Povečaj',
                  zoomOut: 'Pomanjšaj',
                  pan: 'Pomikanje',
                  reset: 'Resetiraj',
                }
              }
            }],
          toolbar: {
            show: false
          },
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          }
        },
        stroke: {
          show: true,
          width: 4,
          curve: 'smooth',
          colors: ['#ED0909', '#AAAAAA']
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            datetimeUTC: false,
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
        },
        yaxis: {
          show: false,
          tickAmount: 3,
          min: 0.0,
          decimalsInFloat: 3,
          labels: {
            show: true
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            format: "dd/MM"
          },
          shared: false
        },
        legend: {
          show: false
        },
        colors: ['#ED0909'],
      },

    }
  },
  props: {
    forAllDevices: Boolean,
    forDevice: String,
    numDays: Number,
    height: Number
  },
  methods: {
    getChart() {
      return axios.get('/api/amr/graph/volume_history', {
        params: {
          forAllDevices: this.forAllDevices,
          forDevice: this.forDevice,
          numDays: this.numDays
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            console.log(`Got chart data for ${this.numDays}`);
            console.log(response);
            // calculate sum
            this.external_access.volume_sum = response.data.volume.reduce((a, b) => a + b, 0).toFixed(1);
            this.external_access.start_time = response.data.start_time;
            this.external_access.end_time = response.data.end_time;
            if (response.data.chart_data.length > 0) {
              this.external_access.time_range = this.external_access.start_time + " - " + this.external_access.end_time
              this.external_access.day = response.data.day;
            } else {
              this.external_access.time_range = response.data.day;
              this.external_access.day = response.data.day;
            }

            this.series = [];
            this.series.push({
              data: response.data.chart_data,
              name: this.$t('volume')
            })
            let max = Math.max.apply(Math, response.data.chart_data.map(function (element) {
              return element.y
            }))

            let show_y_axis = false
            for (let i = 0; i < response.data.volume.length; i++) {
              if (response.data.volume[i] !== null) {
                show_y_axis = true;
                break;
              }
            }

            this.chartOptions.yaxis.show = show_y_axis

            if (max > 0.0)
              this.chartOptions.yaxis.max = max
            else
              this.chartOptions.yaxis.max = 1
            this.$refs.chart.setLocale(this.language);
            this.data_loaded = true;
            this.$refs.chart.refresh();
            //this.$refs.chart.showSeries();
            //this.$refs.chart.render();
          })
    }
  },
  i18n: {
    messages: {
      en: {
        volume_history: "VOLUME HISTORY",
        volume: 'Volume [m³]'
      },
      sl: {
        volume_history: "ZGODOVINA PORABE",
        volume: 'Odčitek [m³]'
      },
    }
  }
}
</script>

<style>
.apexcharts-title-text {
  font-family: Roboto, sans-serif !important;
  font-size: .75rem !important;
  font-weight: 500;
  letter-spacing: .1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
}

.apexcharts-xaxis-label {
  font-family: Roboto, sans-serif !important;
  font-size: .65rem !important;
  font-weight: 500;
  letter-spacing: .0666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
}

</style>